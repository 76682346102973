import { Component, OnInit } from '@angular/core';
import { ApiManagerService } from 'src/app/home/api-manager.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import {Router} from "@angular/router"
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

interface UserDetail {
  user_id: string,
  token: string
}

interface UserHolder {
  user_id: string,
  token: string,
  email: string,
  name: string,
  password: string,
  job: string,
  birthday: string,
  sex: string,
  relationship: string,
  announcements: number,
  fortunes: number,
  users: number
}

interface Fortunes {
  status: string,
  user_id: string,
  token: string
}

interface FortunesWithStat {
  status1: string,
  user_id: string,
  token: string
}

interface FortuneDetail {
  user_id: string,
  token: string,
  fortune_id: string
}

interface RemoveFortune {
  user_id: string,
  token: string,
  fortune_id: number
}

interface User {
  user_id: string,
  token: string
}


@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {
  //ViewParameters
  userProfile = '';
  userAllComentedFortune = 0;
  userAllCommentedCount = 0;

  //UpdateParameters
  gender = 1;
  announcements = 0;
  fortunes = 0;
  users= 0;

  //RightBarActivity
  isFirstActive = true;
  isSecondActive = false;
  isThirtActive = false;
  isFourthActive = false;
  isFifthActive = false;
  isSixthActive = false;

  //UserDetail
  userName = "";
  userEmail = "";
  userJob = "";
  userGender = "";
  userRelationship = "";
  userDOB = "";

  //FortuneController
  fortuneHolderHidden = true; 
  hiddenViewValue = 1;

  //FortuneDetailSource
  bottomMenuControllerValue = 0

  //FortunesDataSource
  fortuneStat = '';
  allFortunes = [];
  fortuneId = '';
  fortuneDetail = '';
  images = [];
  tarotPaths = [];
  path: string;
  paths=[];
  mp3Path = [];
  imgPath = [];
  id: any;
  suggestions = []
  userAmount;
  routerLink: string | any;

  loaderController = 0;
  hiddenLoaderController = 0
  fortuneLoaderController = 0;

  newArray = [];

  statusFortune = [2,3,4];
 
  closeResult = '';

  //InputControllers
  getInformation = new FormGroup(
    {
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      job: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      birthday: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      relationship: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
    }
  );

  constructor(
    private apimanager: ApiManagerService,
    private notifService: NotificationsService,
    private modalService: NgbModal,
    private router: Router 
  ) { }

  ngOnInit() { 
    this.getUserDetail();
    localStorage.removeItem('support_type');
    this.isFirstActive = true;
    this.isSecondActive = false;
    this.isThirtActive = false;
    this.isFourthActive = false;
    this.isFifthActive = false;
    this.isSixthActive = false;
    this.fortuneHolderHidden = true;
    this.hiddenViewValue = 0;
    localStorage.removeItem('selected_fortune_detail_1');
  }

  onClick() {
    console.log('update clicked');
  }

  onBuyClicked() {
    console.log('button clicked');
  }


  getUserDetail() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: UserDetail = {
          token: values.token,
      user_id: values.id
   } 
    
    this.apimanager.getUserDetail(data).subscribe({ 
      next: (response :any) => {
        this.userProfile = response.result;
        console.log(response.result);
        this.userName = response.result.name;
        this.userEmail = response.result.email;
        this.userJob = response.result.job;
        if (response.result.sex == 1) {
          this.userGender = "Erkek"
        } else if (response.result.sex == 2) {
          this.userGender = "Kadın"
        } else if (response.result.sex == 3) {
          this.userGender = "LGBT"
        }
        this.userRelationship = response.result.relationship;
        this.userDOB = response.result.birthday
        console.log('user comment count :', this.userAllComentedFortune)
        this.userAmount = response.result.balance;
        console.log(response.result.balance); 
        this.loaderController = 1

        for (let i = 0; i < response.result.fortune_counts.length; i++) {
           this.userAllCommentedCount +=  response.result.fortune_counts[i].fortune_count
        }

        console.log('mali : ', this.userAllCommentedCount);
        

      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

//CommentedFortunesDataSource
  getUserCommented(value: any) {
    if (value == 2) {
      
      //this.getLastFortuneWithStatus(`2`);

      //this.getLastFortuneWithStatus(`3,`);
 
      this.getLastFortuneWithStatus(`status[]=2&status[]=3&status[]=4`);  

     // https:\\api2.falevi.com.tr\notifications\?user_id=$userId&token=$userToken&status[]=2&status[]=3&status[]=4
    } else {
      this.getStatus(value)
    }
  }

  getLastFortuneWithStatus(value1) {
    this.newArray = [];
    this.allFortunes = [];
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var dataFortunes: FortunesWithStat = {
            token: values.token,
        user_id: values.id,
        status1: `${value1}`
    } 
    this.apimanager.getAllFortunesStatus(dataFortunes).subscribe({ 
      next: (response :any) => {
        this.fortuneLoaderController = 3; 
        this.userAllComentedFortune = response.result.length;
        this.hiddenLoaderController = 2;
        this.allFortunes.push(response.result);
        this.loaderController = 3; 
        

        for (let iFirst = 0; iFirst < this.allFortunes.length; iFirst++) {  
          for (let iSecond = 0; iSecond < this.allFortunes[iFirst].length; iSecond++) { 
            for (let iThirt = 0; iThirt < this.allFortunes[iFirst].length; iThirt++) { 
              console.log("mali 33: ", this.allFortunes[iFirst][iSecond]);  
              if (this.allFortunes[iFirst][iSecond].id != this.allFortunes[iFirst][iThirt].id) { 
                if (!this.newArray.includes(this.allFortunes[iFirst][iSecond])) {
                  if (this.allFortunes[iFirst][iSecond].is_read) {
                    this.newArray.push(this.allFortunes[iFirst][iSecond])
                  } else {
                    this.newArray.push(this.allFortunes[iFirst][iSecond])
                  }
                }
              }
            }
          }
        }   
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }


  getStatus(value) {
    this.newArray = [];
    this.allFortunes = [];
    console.log('clicked value', value);
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var dataFortunes: Fortunes = {
            token: values.token,
        user_id: values.id,
        status : `${value}`
    } 
    this.apimanager.getAllFortunes(dataFortunes).subscribe({ 
      next: (response :any) => {
        this.fortuneLoaderController = 3; 
        this.userAllComentedFortune = response.result.length;
        this.hiddenLoaderController = 2;
        this.allFortunes.push(response.result);
        this.loaderController = 3; 
        this.newArray = response.result 
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSendClick() { 
     if (this.getInformation.get('email').value == "") {
      this.getInformation.get('email').setValue(this.userEmail)
     } 

     if (this.getInformation.get('name').value == "") {
      this.getInformation.get('name').setValue(this.userName)
     } 

     if (this.getInformation.get('password').value == "") {
       console.log("password is locked"); 
     } 

     if (this.getInformation.get('job').value == "") {
      this.getInformation.get('job').setValue(this.userJob)
     } 

     if (this.getInformation.get('birthday').value == "") {
      this.getInformation.get('birthday').setValue(this.userDOB)
     } 

     if (this.getInformation.get('relationship').value == "") {
      this.getInformation.get('relationship').setValue(this.userRelationship)
     }  

     this.updateUserDetail()
  }

  updateUserDetail() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: UserHolder = {
      user_id: values.id,
          token: values.token,
      email: this.getInformation.get('email').value,
      name: this.getInformation.get('name').value,
      password: this.getInformation.get('password').value,
      job: this.getInformation.get('job').value,
      birthday: this.getInformation.get('birthday').value,
      sex: this.gender.toString(),
      relationship: this.getInformation.get('relationship').value,
      announcements: this.announcements,
      fortunes: this.fortunes,
      users: this.users
    }

    this.apimanager.updateUser(data).subscribe({ 
      next: (response :any) => {
        console.log('user-response', response);
        this.onSucces('Bilgileriniz Başarılı Bir Şekilde Güncellendi.')
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
          this.onError('Bilgilerinizi Güncellerken Hata Oluştu.')
        } else {
          console.log(' error : ', err);
          this.onError('Bilgilerinizi Güncellerken Hata Oluştu.')
        }
      }
    });
  }  

  onSelectSubmit(value) {
    if (this.userGender != null) {
      if (this.userGender == 'Erkek') {
        this.gender = 1
        console.log('gender : ', this.gender);
      } else if (value == 'Kadın') {
        this.gender = 2
        console.log('gender : ', this.gender);
      } else if (value == 'LGBT') {
        this.gender = 3
        console.log('gender : ', this.gender);
      }
    } else {
      if (value == 'male') {
        this.gender = 1
        console.log('gender : ', this.gender);
      } else if (value == 'female') {
        this.gender = 2
        console.log('gender : ', this.gender);
      } else if (value == 'lgbt') {
        this.gender = 3
        console.log('gender : ', this.gender);
      }
    }
   
  }

  onSubmitRelationship(value) {
    this.getInformation.get('relationship').setValue(value) 
  }

  //LeftMenuController
  onClickLeftMenu(id: number) {
    if (id == 1) {
      this.isFirstActive = true
      this.isSecondActive = false
      this.isThirtActive = false
      this.isFourthActive = false
      this.isFifthActive = false
      this.isSixthActive = false
      this.fortuneHolderHidden = true
      this.hiddenViewValue = 0
    } else if (id == 2) {
      this.isFirstActive = false
      this.isSecondActive = true
      this.isThirtActive = false
      this.isFourthActive = false
      this.isFifthActive = false
      this.isSixthActive = false
      this.fortuneHolderHidden = true
    } else if (id == 3) {
      this.isFirstActive = false
      this.isSecondActive = false
      this.isThirtActive = true
      this.isFourthActive = false
      this.isFifthActive = false
      this.isSixthActive = false
      this.fortuneHolderHidden = false
      this.hiddenViewValue = 2
      this.getUserCommented(2)
    } else if (id == 4) {
      this.isFirstActive = false
      this.isSecondActive = false
      this.isThirtActive = false
      this.isFourthActive = true
      this.isFifthActive = false
      this.isSixthActive = false
      this.fortuneHolderHidden = true
    } else if (id == 5) {
      this.isFirstActive = false
      this.isSecondActive = false
      this.isThirtActive = false
      this.isFourthActive = false
      this.isFifthActive = true
      this.isSixthActive = false
      this.fortuneHolderHidden = true
    } else if (id == 6) {
      this.isFirstActive = false
      this.isSecondActive = false
      this.isThirtActive = false
      this.isFourthActive = false
      this.isFifthActive = false
      this.isSixthActive = true
      this.fortuneHolderHidden = true
    }
  }

  //Error-Success Messages
  onSucces(message: String) {
    this.notifService.success('Merhaba',message, {
      position: ['top', 'right'],
      timeOut: 4000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  onError(message: String) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 4000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  //FortuneController
  fortuneViewerController(value: any) {
    this.hiddenViewValue = value
    this.loaderController = 0
    if (value == 2) {  
      this.getUserCommented(2)  
    }  
    if (value == 1) {
      this.getUserCommented(1) 
    } 
    if (value == 5) {
      this.getUserCommented(5)
    } 
  }

  //FortuneTableDataSource-Delegate
  onFortuneDetailClicked(value) {
    console.log('selected fortune : ', value)
    this.getFortuneDetail(value)
  } 
  //FortuneDetailDataSource
  getFortuneDetail(fortune_id) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: FortuneDetail = {
            token: values.token,
        user_id: values.id,
        fortune_id: fortune_id
    }
    this.apimanager.getFortuneDetail(data).subscribe({ 
      next: (response :any) => {
        if (response.result.path == null) {
          console.log('null');
        } else if (response.result.path != null) {
          this.paths = response.result.path.split(',')
          for (let i = 0; i < this.paths.length; i++) {
            if (this.paths[i].includes("mp3")) {
              this.mp3Path = this.paths[i];
            } else {
              console.log(this.paths[i]);
              this.imgPath.push(this.paths[i])
            }
          }
        } 
        if (response.result.selected_cards == null) {
          console.log('null');
        } else if (response.result.selected_cards != null) {
          this.tarotPaths = response.result.selected_cards.split(',');
        }

        if (response.result.images == null) {
          console.log('null');
        } else if (response.result.images != null) {
          this.images = response.result.images;
        }
        this.fortuneDetail = response.result; 
        this.suggestions = response.result.suggestions;
        localStorage.setItem('selectedFortuneType', response.result.fortune_type_id);

        console.log('fortune_detail : ', response.result);  

        localStorage.setItem('selected_fortune_detail_1', JSON.stringify(response.result));

        this.bottomMenuControllerValue = 1 

        this.router.navigate(['fortune-detail'])
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }


  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
