import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { env } from 'process';
import { ApiManagerService } from 'src/app/home/api-manager.service';

interface UserHolder {
  user_id: string,
  token: string,
  email: string,
  name: string,
  password: string,
  job: string,
  birthday: string,
  sex: string,
  relationship: string,
  announcements: number,
  fortunes: number,
  users: number
}

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  gender = 1;
  announcements = 0;
  fortunes = 0;
  users= 0;

  getInformation = new FormGroup(
    {
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      job: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      birthday: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      relationship: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
    }
  );

  constructor(
    private http: ApiManagerService,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() { }

  onSendClick() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: UserHolder = {
      user_id: values.id,
          token: values.token,
      email: this.getInformation.get('email').value,
      name: this.getInformation.get('name').value,
      password: this.getInformation.get('password').value,
      job: this.getInformation.get('job').value,
      birthday: this.getInformation.get('birthday').value,
      sex: this.gender.toString(),
      relationship: this.getInformation.get('relationship').value,
      announcements: this.announcements,
      fortunes: this.fortunes,
      users: this.users
    }

    this.http.updateUser(data).subscribe({ 
      next: (response :any) => {
        console.log('user-response', response);
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSelectSubmit(value) {
    if (value == 'male') {
      this.gender = 1
      console.log('gender : ', this.gender);
    } else if (value == 'female') {
      this.gender = 2
      console.log('gender : ', this.gender);
    } else if (value == 'lgbt') {
      this.gender = 3
      console.log('gender : ', this.gender);
    }
  }

  onInputChange(event, eventSecond) {
    if (event.target.checked) {
      if (eventSecond == 1) {
        this.announcements = 1
      } else { return this.announcements }
    }
  }
  onInputChangeFortunes(event, eventSecond) {
    if (event.target.checked) {
      if (eventSecond == 2) {
       this.fortunes = 1
      } else { return this.fortunes }
    }
  }

  onInputChangeUsers(event, eventSecond) {
    if (event.target.checked) {
      if (eventSecond == 3) {
       this.users = 1
      } else { return this.users }
    }
  }
}
