import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService as facebookAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

interface SignupCredentials {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface FacebookSignIn {
  facebook_token: string;
  device_type: string;
  device_id: string;
}

interface SigninCredentials {
  email: string;
  password: string;
}

interface SignupResponse {
  code: number;
  result: {
    email: string,
    token: string,
    id: string,
  };
}

interface AnonyUserResponse {
  result: {
    email: string,
    token: string,
    id: string
  }
}

interface SignedinResponse {
    authenticated: Boolean,
    token: string,
    id: string,
}

interface TellerList {
  identifier: string,
  token: string,
  fortuneTypeId: string
}

interface ForgotMYPassword {
  identifier: string,
  token: string,
  email: string
}

interface AnonimUser {
  device_type: string,
  device_id: string
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  rootUrl = 'https://api2.falevi.com.tr'
  facebookApiRootUrl = ""
  signedin$ = new BehaviorSubject(false);
  anonySignedIn$ = new BehaviorSubject(false);
  user_id = '';
  user_token = '';
  private user: SocialUser;
  private loggedIn: boolean;

  constructor(private http: HttpClient,
    private facebookAuthService: facebookAuthService) { }

  signup(credentials: SignupCredentials){
    var ip = localStorage.getItem('curren_user_ip_id')

    let headers = new HttpHeaders();
    let city = localStorage.getItem('current_user_city')
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("name",credentials.name)
    .set("email", credentials.email)
    .set('city', city)
    .set('password', credentials.passwordConfirmation)
    .set('device_type', 'Web') 
    .set('device_id', ip)

    return this.http.get<SignupResponse>(`${this.rootUrl}/register`, { headers: headers, params: params}).pipe(
      tap( response => {
        if (response.result.id && response.result.token) {
            console.log('result', response.result);
            localStorage.setItem('currentUser', JSON.stringify(response.result));
            this.user_token = JSON.stringify(response.result.token);
            this.user_id = JSON.stringify(response.result.id);
            this.signedin$.next(true);
          }
      })
    );
  }



  sigin(credentials: SigninCredentials){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("email", credentials.email)
    .set('password', credentials.password)

    return this.http.get<SignupResponse>(`${this.rootUrl}/login`, { headers: headers, params: params}).pipe(
      tap( response => {
        if (response.result.id && response.result.token) {
          console.log('result', response.result);
            localStorage.setItem('currentUser', JSON.stringify(response.result));
            this.signedin$.next(true);
          }
      })
    );
  } 

  signInWithFacebook(creadentials: FacebookSignIn, fbtoken: string) { 
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
      .set("facebook_token", fbtoken)
      .set("device_id", creadentials.device_id)
      .set("device_type", creadentials.device_type);   

    return this.http.get<SignupResponse>(`https://api2.falevi.com.tr/facebook2`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           localStorage.setItem('currentUser', JSON.stringify(response.result));
           console.log('response is not empty');
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  forgotMyPassword(credentials: ForgotMYPassword) {

  }

  signInAnony(credentials: AnonimUser) {
    let params = new HttpParams()
    .set("device_type", credentials.device_type)
    .set("device_id", credentials.device_id)
  
    return this.http.post(`${this.rootUrl}/guest`, params).pipe(
      tap( response => {
         if (response) {
          localStorage.setItem('anonyUser', JSON.stringify(response));
           this.anonySignedIn$.next(true)
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  checkAuth(){
    var values = JSON.parse(localStorage.getItem("currentUser"));
    if (values) {
      let data: SignedinResponse = {
        authenticated: true,
        token: values.token,
        id: values.id
      }
      this.signedin$.next(true);
      localStorage.removeItem("anonyUser")
      this.checkAnony()
      return this.signedin$;
    }
    this.signedin$.next(false);
    return this.signedin$;
  }

  checkAnony() {
    if (this.signedin$) {
      this.anonySignedIn$.next(false)
    } else {
      var values = JSON.parse(localStorage.getItem("anonyUser"));
      if (values) {
  
        let data: SignedinResponse = {
          authenticated: true,
          token: values.token,
          id: values.id
        }
        this.anonySignedIn$.next(true);
        return this.anonySignedIn$;
      }
      this.anonySignedIn$.next(false);
      return this.anonySignedIn$;
    }
  }

  signOut() {
    localStorage.removeItem("currentUser");
    //this.facebookAuthService.signOut()
    this.facebookAuthService.signOut(true)
    this.signedin$.next(false);
    return this.signedin$;
  }

  getTellerList() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    let data: SignedinResponse = {
        authenticated: true,
        token: values.token,
        id: values.id
    }
    
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("fortune_type_id", "1")
    .set("user_id", data.id)
    .set("token", data.token);

    return this.http.get<string>(`${this.rootUrl}/fortune-teller/list/free`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response is not empty');
         } else {
           console.log('response is empty');
         }
      })
    );
  } 
}

function switchMap(arg0: (mapValue: any) => any): import("rxjs").OperatorFunction<Object, unknown> {
  throw new Error('Function not implemented.');
}

