import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { parse } from 'querystring';

interface TellerToken {
  user_id: string,
  token: string,
  fortune_type_id: string
};

interface TellerDetailToken {
  user_id: string,
  token: string,
  fortune_type_id: string
  teller_id: number
}

interface UserDetail {
  user_id: string,
  token: string
}

interface Fortunes {
  status: string,
  user_id: string,
  token: string
}

interface FortunesWithStat {
  status1: string,
  user_id: string,
  token: string
}

interface FortuneDetail {
  user_id: string,
  token: string,
  fortune_id: string
}

interface FavouriteTellers {
  user_id: string,
  token: string,
  fortune_type_id: string
}

interface FortunePhoto {
  photo: File,
  user_id: string,
  token: string
}

interface FortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  image: string,
}

interface TarotFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  tarot_types_id: number,
  selected_cards: any
}

interface KatinaFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  tarot_types_id: number,
  selected_cards: any
}

interface DuruFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  image: string,
}

interface YildiznameFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  birthday_hour: string,
  city: string,
  birthday_city: string
}

interface UserHolder {
  user_id: string,
  token: string,
  email: string,
  name: string,
  password: string,
  job: string,
  birthday: string,
  sex: string,
  relationship: string,
  announcements: number,
  fortunes: number,
  users: number
}

interface TellerDetailResponse {
  code: number,
  result: {
    about_text: string,
    audiable: Boolean,
    can_express: Boolean,
    comments_count: Boolean,
    credit: number,
    duru_online_status: string,
    express_credit: number,
    favourite_count: number,
    fortune_online_status: string,
    id: number,
    image: string,
    is_active: Boolean,
    is_favourited: Boolean,
    katina_online_status: string,
    last_login: string,
    membership_date: string,
    name: string,
    notify_me: Boolean,
    online_status: string,
    only_audiable: true,
    pending_count: number,
    point: number,
    response_minute: number,
    scores: [string],
    star: number,
    status_text: string,
    tarot_online_status: string,
    user_type: string,
    yildiz_name_online_status: string
  }
}

interface SupportHolder {
  user_id : string,
  token: string,
  type_id: string,
  name: string,
  fortune_id: string,
  message: string,
  email: string
}

interface TellerCommentHolder {
  user_id: string,
  token: string,
  comment: string,
  score: number,
  fortune_id: string
}

interface FortuneQuestion {
  user_id: string,
  token: string,
  question: string,
  fortune_id: string
}

interface TarotCards {
  user_id: string,
  token: string
}

interface KatinaCards {
  user_id: string,
  token: string
}

interface AddToFavs {
  user_id: string,
  token: string,
  teller_id: string
}

interface RemoveFortune {
  user_id: string,
  token: string,
  fortune_id: number
}


@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {
  rootUrl = 'https://api2.falevi.com.tr';

  constructor(private http: HttpClient) { }

  getTellerList(credentials: TellerToken) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("fortune_type_id", credentials.fortune_type_id)
    .set("user_id", credentials.user_id)
    .set("token", credentials.token);

    return this.http.get<String>(`${this.rootUrl}/fortune-teller/list/free`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  getDetail(credentials: TellerDetailToken) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("fortune_type_id", credentials.fortune_type_id)
    .set("user_id", credentials.user_id)
    .set("token", credentials.token)

    return this.http.get<TellerDetailResponse>(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/detail`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  getUserDetail(credentials: UserDetail) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("user_id", credentials.user_id)
    .set("token", credentials.token);

    return this.http.get<any>(`${this.rootUrl}/user`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  getAllFortunes(credentials: Fortunes) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("user_id", credentials.user_id)
    .set("token", credentials.token)
    .set("status[]", credentials.status);

    return this.http.get<String>(`${this.rootUrl}/notifications`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response', response);
           
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  getAllFortunesStatus(credentials: FortunesWithStat) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams() 

    return this.http.get<String>(`${this.rootUrl}/notifications?user_id=${credentials.user_id}&token=${credentials.token}&status[]=2&status[]=3&status[]=4`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response', response);
           
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }


  removeFortune(credentials: RemoveFortune) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        user_id: credentials.user_id,
        token: credentials.token,
        fortune_id: credentials.fortune_id
      },
    };
    
    return this.http.delete(`${this.rootUrl}/notification`, options).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );

  }

  getFortuneDetail(credentials: FortuneDetail) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("user_id", credentials.user_id)
    .set("token", credentials.token)
    .set("fortune_id", credentials.fortune_id);

    return this.http.get<String>(`${this.rootUrl}/fortune`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response', response);
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  getFavsTeller(credentials: FavouriteTellers) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set("user_id", credentials.user_id)
    .set("token", credentials.token)
    .set("fortune_type", credentials.fortune_type_id);

    return this.http.get<String>(`${this.rootUrl}/favourites`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response', response);
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }


  getTarotCards(credentials: TarotCards) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token);

    return this.http.get<String>(`${this.rootUrl}/tarot-cards`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response', response);
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  getKatinaCards(credentials: KatinaCards) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token);

    return this.http.get<String>(`${this.rootUrl}/katina-cards`, {headers: headers, params: params}).pipe(
      tap( response => {
         if (response) {
           console.log('response', response);
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }


  sendFortune(credentials: FortuneHolder) {

    if (credentials.image.length === 3) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             console.log('url', )
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    } else if (credentials.image.length === 4) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])
      .set("image[4]", credentials.image[3])


      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             console.log('url', )
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    } else if (credentials.image.length === 5) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])
      .set("image[4]", credentials.image[3])
      .set("image[5]", credentials.image[4])

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             console.log('url', )
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    } else if (credentials.image.length === 6) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])
      .set("image[4]", credentials.image[3])
      .set("image[5]", credentials.image[4])
      .set("image[6]", credentials.image[5])

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             console.log('url', )
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    }
  }

  sendDuru(credentials: DuruFortuneHolder) {
    if (credentials.image.length === 3) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2]);

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/dur-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    } else if (credentials.image.length === 4) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])
      .set("image[4]", credentials.image[3]);

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/dur-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    } else if (credentials.image.length === 5) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])
      .set("image[4]", credentials.image[3])
      .set("image[5]", credentials.image[4])

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/dur-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    } else if (credentials.image.length === 5) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("image[1]", credentials.image[0])
      .set("image[2]", credentials.image[1])
      .set("image[3]", credentials.image[2])
      .set("image[4]", credentials.image[3])
      .set("image[5]", credentials.image[4])
      .set("image[6]", credentials.image[5])

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/dur-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('response', response);
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
    }
  }

  sendTarot(credentials: TarotFortuneHolder) {
      let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("tarot_types_id", credentials.tarot_types_id.toString())
      .set("selected_cards", credentials.selected_cards)

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/tarot-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             console.log('tarot-response : ', response);
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
  }

  sendKatina(credentials: KatinaFortuneHolder) {
    let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("tarot_types_id", credentials.tarot_types_id.toString())
      .set("selected_cards", credentials.selected_cards)
      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/katina-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
  }

  sendYildiz(credentials: YildiznameFortuneHolder) {
    let params = new HttpParams()
      .set("user_id", credentials.user_id)
      .set("token", credentials.token)
      .set("fortune_type", credentials.fortune_type)
      .set("name", credentials.name)
      .set("birthday", credentials.birthday)
      .set("relationship", credentials.relationship)
      .set("sex", credentials.sex.toString())
      .set("job", credentials.job)
      .set("email", credentials.email)
      .set("question", credentials.question)
      .set("send_to_pool", credentials.send_to_pool.toString())
      .set("is_express", credentials.is_express.toString())
      .set("is_audio", credentials.is_audio.toString())
      .set("birthday_hour", credentials.birthday_hour)
      .set("city", credentials.city)
      .set("birthday_city", credentials.birthday_city);

      return this.http.post(`${this.rootUrl}/fortune-teller/${credentials.teller_id}/yildiz-name-fortune/new`, params).pipe(
        tap( response => {
           if (response) {
             return response
           } else {
             console.log('response is empty');
           }
        })
      );
  }

  sendFortunePhoto(credentials: FortunePhoto) {
    const formData = new FormData();
    formData.append('image', credentials.photo)
    formData.append('user_id', credentials.user_id)
    formData.append('token', credentials.token);

    return this.http.post(`${this.rootUrl}/fortune/photo/upload`, formData).pipe(
      tap( response => {
        if (response) {
          return response
        } else {
          console.log('photo response is empty');
        }
     })
    );
  }

  sendSupport(credentials: SupportHolder) {
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token)
    .set('type_id', credentials.type_id)
    .set('name', credentials.name)
    .set('email', credentials.email)
    .set('fortune_id', credentials.fortune_id)
    .set('message', credentials.message);

    return this.http.post(`${this.rootUrl}/support`, params).pipe(
      tap( response => {
         if (response) {
           console.log('support result', response);
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  updateUser(credentials: UserHolder) {
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token)
    .set('email', credentials.email)
    .set('city', "izmir")
    .set('name', credentials.name)
    .set('password', credentials.password)
    .set('job', credentials.job)
    .set('birthday', credentials.birthday)
    .set('sex', credentials.sex)
    .set('relationship', credentials.relationship)
    .set('announcements', credentials.announcements.toString())
    .set('fortunes', credentials.fortunes.toString())
    .set('users', credentials.users.toString());

    return this.http.post(`${this.rootUrl}/user`, params).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  addComment(credentials: TellerCommentHolder) {
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token)
    .set('comment', credentials.comment)
    .set('score', `${credentials.score}`);

    console.log(params);
    
    return this.http.post(`${this.rootUrl}/fortune/${credentials.fortune_id}/rate`, params).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }


  addQuestions(credentials: FortuneQuestion) {
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token)
    .set('question', credentials.question);

    return this.http.post(`${this.rootUrl}/fortune/${credentials.fortune_id}/question`, params).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }


  

  addToFavs(credentials: AddToFavs) {
    let params = new HttpParams()
    .set('user_id', credentials.user_id)
    .set('token', credentials.token);

    return this.http.post(`${this.rootUrl}/favourite/${credentials.teller_id}`, params).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }

  removeFromFavs(credentials: AddToFavs) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        user_id: credentials.user_id,
        token: credentials.token,
      },
    };
    

    return this.http.delete(`${this.rootUrl}/favourite/${credentials.teller_id}`, options).pipe(
      tap( response => {
         if (response) {
           return response
         } else {
           console.log('response is empty');
         }
      })
    );
  }
}
