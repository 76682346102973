import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router"

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  constructor(
    private router: Router,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() {
    var user = JSON.parse(localStorage.getItem("currentUser"));
    if (user == null) {
      this.router.navigate([''])
    }
  }
}
