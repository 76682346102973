import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiManagerService } from 'src/app/home/api-manager.service';

interface FortuneQuestion {
  user_id: string,
  token: string,
  question: string,
  fortune_id: string
}

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent implements OnInit {


  getComment = new FormGroup(
    {
      comment: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    }
  );


  constructor(
    private apiManager : ApiManagerService,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])}

  ngOnInit() {
  }

  onClickSend() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: FortuneQuestion = {
            token: values.token,
        user_id: values.id,
        fortune_id: localStorage.getItem("teller_id_add_comment"),
        question: this.getComment.get('comment').value
    }

    this.apiManager.addQuestions(data).subscribe({ 
      next: (response :any) => {
        console.log(response.result)
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }
}
