import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiManagerService } from 'src/app/home/api-manager.service';

interface SupportHolder {
  user_id : string,
  token: string,
  type_id: string,
  name: string,
  fortune_id: string,
  message: string,
  email: string
}

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  supportType : number;
  supportTypeFromFortunes: number;

  getSupport = new FormGroup(
    {
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      message: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
      fortune_id: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100),
      ]),
    }
  );

  constructor(
    private http: ApiManagerService,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() {
    if (localStorage.getItem('support_type') == '3') {
      this.onSelectSubmit('sikayet');
    } else { return }
  }


  onSendClick(value) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: SupportHolder = {
          token: values.token,
      user_id: values.id,
      type_id: this.supportType.toString(),
      name: this.getSupport.get('name').value,
      email: this.getSupport.get('email').value,
      fortune_id: this.getSupport.get('fortune_id').value,
      message: this.getSupport.get('message').value
    }
    this.http.sendSupport(data).subscribe({ 
      next: (response :any) => {
        console.log('support-response', response);
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSelectSubmit(value) {
    if (value === 'odeme') {
      this.supportType = 1
    } else if (value === 'teknik') {
      this.supportType = 2
    } else if (value === 'sikayet') {
      this.supportType = 3
    } else if (value === 'yorumcu-basvurusu') {
      this.supportType = 6
    } else if (value === 'diger') {
      this.supportType = 5
    }
    
    console.log(value);
  }

}
