import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { SignoutComponent } from './signout/signout.component';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { MyFortunesComponent } from '../my-fortunes/my-fortunes.component';
import { MoreOptionsComponent } from '../more-options/more-options.component';

const routes: Routes = [
  { path: 'signout', component: SignoutComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'signup', component: SignupComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'signin', component: SigninComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'profile', component: UserProfileComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'my-fortunes', component: MyFortunesComponent ,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'more-options', component: MoreOptionsComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
