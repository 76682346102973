import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ApiManagerService } from '../api-manager.service';
import { SendFortuneComponent } from '../send-fortune/send-fortune.component';

interface KatinaCards {
  user_id: string,
  token: string
}

@Component({
  selector: 'app-send-katina',
  templateUrl: './send-katina.component.html',
  styleUrls: ['./send-katina.component.scss']
})
export class SendKatinaComponent implements OnInit {

  katinaCards = [];
  katinaPaths = [];
  katinaType : number;
  card_id = [];
  isOk = false;
  selectedTypeCardCount = 0;

  constructor(
    private apiManagerService: ApiManagerService,
    private sendFortune: SendFortuneComponent,
    private notifService: NotificationsService
  ) { }

  ngOnInit() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: KatinaCards = {
      user_id : values.id,
      token : values.token
    }

    this.apiManagerService.getKatinaCards(data).subscribe({ 
      next: (response :any) => {
        this.katinaCards = response.result;
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSubmitType(value) {
    if (value === 'imparator') {
      this.katinaType = 2
      this.sendFortune.katinaType = 2
      this.selectedTypeCardCount = 10
    } else if (value === 'iliski') {
      this.katinaType = 4
      this.sendFortune.katinaType = 4
      this.selectedTypeCardCount = 7
    } else if (value === 'karar') {
      this.katinaType = 6
      this.sendFortune.katinaType = 6
      this.selectedTypeCardCount = 7
    }
  }

  onCardClicked(value, event, id) {
   if (this.card_id.indexOf(event) <= -1 ) {
    if (this.katinaType === 2) {
      if (this.katinaPaths.length === 10) {
        console.log("lenght is full");
        console.log(this.katinaPaths);
        this.sendFortune.isOk = 1
        this.isOk = true
        this.onError('En fazla 10 kart seçebilirsiniz.')
      } else {
         console.log("lengt is not full");
         this.katinaPaths.push(`${value}`);
         this.sendFortune.katinaPaths.push(`${value}`);
         this.card_id.push(event);
      }
    } else if (this.katinaType === 4 || this.katinaType === 6) {
      if (this.katinaPaths.length === 7) {
        console.log("lenght is full");
        console.log(this.katinaPaths);
        this.sendFortune.isOk = 1;
        this.isOk = true;
        this.onError('En fazla 10 kart seçebilirsiniz.')
      } else {
         console.log("lengt is not full");
         this.katinaPaths.push(`${value}`);
         this.sendFortune.katinaPaths.push(`${value}`);
         this.card_id.push(event)
      }
    }
   } else {
     const index: number = this.card_id.indexOf(event);
     const indexSecond: number = this.katinaPaths.indexOf(value);
     this.card_id.splice(index, 1);
     this.katinaPaths.splice(indexSecond, 1);
     this.sendFortune.katinaPaths.splice(indexSecond, 1);
     this.onError('Katlarınızı seçerken lütfen dikkatli seçiniz.')
   }
  }

  onError(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 3000,
      animate: 'fade',
      showProgressBar: true
    });
  }

}
