import { Component, OnInit, EventEmitter } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { AppComponent } from 'src/app/app.component';
import { ApiManagerService } from 'src/app/home/api-manager.service';
import { ContactUsComponent } from 'src/app/more-options/contact-us/contact-us.component';
import { AddCommentComponent } from '../add-comment/add-comment.component';

interface Fortunes {
  status: string,
  user_id: string,
  token: string
}

interface FortuneDetail {
  user_id: string,
  token: string,
  fortune_id: string
}

interface RemoveFortune {
  user_id: string,
  token: string,
  fortune_id: number
}

interface User {
  user_id: string,
  token: string
}

@Component({
  selector: 'app-all-fortunes',
  templateUrl: './all-fortunes.component.html',
  styleUrls: ['./all-fortunes.component.scss'],
  providers: [ContactUsComponent]
})

export class AllFortunesComponent implements OnInit {
  fortuneStat = '';
  allFortunes = [];
  fortuneId = '';
  fortuneDetail = '';
  images = [];
  tarotPaths = [];
  path: string;
  paths=[];
  mp3Path = [];
  imgPath = [];
  id: any;
  suggestions = []
  userAmount;
  routerLink: string | any;
  

  constructor(
    private apiManager: ApiManagerService,
    private notifService: NotificationsService,
    private support: ContactUsComponent
  ) { }

  ngOnInit() {
    if (!this.fortuneStat) {
      this.fortuneStat = "2";
    } else {
      console.log('not empty', this.fortuneStat);
    }
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: Fortunes = {
            token: values.token,
        user_id: values.id,
        status : this.fortuneStat
    } 
    this.apiManager.getAllFortunes(data).subscribe({ 
      next: (response :any) => {
        this.allFortunes = response.result;
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
    this.getUser();
    localStorage.removeItem('support_type');
  }

  onClick(value: string) {
    this.fortuneStat = value;
    this.fortuneDetail = '';
    this.ngOnInit();
  }

  onClickDetail(value: string) {
    this.fortuneId = value;
    this.getFortuneDetail();
  }

  onAddCommentClicked(event) {
    localStorage.removeItem("teller_id_add_comment")
    this.id = event;
    localStorage.setItem("teller_id_add_comment" ,this.id);
    console.log(this.id);
  }

  onAddQuestionClicked(event) {
    localStorage.removeItem("teller_id_add_comment")
    this.id = event;
    localStorage.setItem("teller_id_add_comment" ,this.id);
    console.log(this.id);
  }

  getFortuneDetail() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: FortuneDetail = {
            token: values.token,
        user_id: values.id,
        fortune_id: this.fortuneId
    }
    this.apiManager.getFortuneDetail(data).subscribe({ 
      next: (response :any) => {
        if (response.result.path == null) {
          console.log('null');
        } else if (response.result.path != null) {
          this.paths = response.result.path.split(',')
          for (let i = 0; i < this.paths.length; i++) {
            if (this.paths[i].includes("mp3")) {
              this.mp3Path = this.paths[i];
            } else {
              console.log(this.paths[i]);
              this.imgPath.push(this.paths[i])
            }
          }
        } 

        if (response.result.selected_cards == null) {
          console.log('null');
        } else if (response.result.selected_cards != null) {
          this.tarotPaths = response.result.selected_cards.split(',');
        }

        if (response.result.images == null) {
          console.log('null');
        } else if (response.result.images != null) {
          this.images = response.result.images;
        }
        this.fortuneDetail = response.result; 
        this.suggestions = response.result.suggestions;
        localStorage.setItem('selectedFortuneType', response.result.fortune_type_id);
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onRemoveFortuneClicked(event) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: RemoveFortune = {
            token: values.token,
        user_id: values.id,
        fortune_id: event
    }

    this.apiManager.removeFortune(data).subscribe({ 
      next: (response :any) => {
        console.log('remove fortune', response.result);
        this.ngOnInit();
        this.onSuccesAllFortunes('Falınız başarılı bir şekilde silindi')
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  getUser() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: User = {
            token: values.token,
        user_id: values.id,
    } 

    this.apiManager.getUserDetail(data).subscribe({
      next: (response) => {
        this.userAmount = response.result.balance;
        console.log(response.result.balance);
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSendFortuneClicked(event: any, eventSecond: any) {
    if (this.userAmount >= eventSecond) {
      this.routerLink = ['send-fortune/', event];
      console.log('USER_AMOUNT');
      localStorage.setItem('selectedFortuneTeller', event);
    } else if (eventSecond > this.userAmount) {
      console.log('TELLER_CREDIT');
      this.onErrorAllFortunes('Altınınız Yetersiz')
      this.routerLink = [''];
    }
  }

  onSupportFortuneClicked(event) {
    localStorage.setItem('support_type', '3')
    console.log(event);
  }


  onSuccesAllFortunes(message) {
    this.notifService.success('Tamamdır!!',message, {
      position: ['top', 'right'],
      timeOut: 3000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  onErrorAllFortunes(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }
}
