import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ApiManagerService } from '../api-manager.service';
import {ActivatedRoute, Router} from "@angular/router"
import { userInfo } from 'os'; 
import { HttpClient } from '@angular/common/http'
import { switchMap } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

interface FortunePhoto {
  photo: File,
  user_id: string,
  token: string
}

interface FortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  image: any
}

interface TarotFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  tarot_types_id: number,
  selected_cards: any
}

interface DuruFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  image: any
}

interface KatinaFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  tarot_types_id: number,
  selected_cards: any
}

interface YildiznameFortuneHolder {
  user_id: string,
  token: string,
  fortune_type: string,
  name: string,
  birthday: string,
  relationship: string,
  sex: number,
  job: string,
  email: string,
  question: string,
  send_to_pool: number,
  is_express: number,
  is_audio: number,
  teller_id: string,
  birthday_hour: string,
  city: string,
  birthday_city: string
}

interface TellerDetailToken {
  user_id: string,
  token: string,
  fortune_type_id: string
  teller_id: number
}

interface UserDetail {
  user_id: string,
  token: string
}

@Component({
  selector: 'app-send-fortune',
  templateUrl: './send-fortune.component.html',
  styleUrls: ['./send-fortune.component.scss']
})
export class SendFortuneComponent implements OnInit {
   @Input() photo: File = null;
   code: any;
   imagesPath = [];
   uploadImages = [];
   spesificIssue='';
   isOk;
   gender;
   tarotPaths = [];
   tarotType: number;
   katinaPaths = [];
   katinaType: number;
   yildizHour: string;
   yildizMinute: string;
   yildizBirthPlace: string;
   fortuneType: string;
   send_to_pool = 0;
   is_express = 0;
   is_audio = 0;
   tellerDetail = ""
   relationShip : string;
   imageSrc: string;
   imageURL: string;
   uploadForm = new FormGroup({ });
   textIsChecked = false;
   audioIsChecked = false;
   checkedType : number;
   requiredIsTrue = false;

   tellerHasCanExpress = false;
   tellerHasAudiable = false;
   tellerHasOnlyAudiable = false; 
   userDetail : any;

   tellerCredit = 0;
   userDetailBalance = 0; 

   userRelationship = "";
   userGender = "";
   userName = "";
   userDOB = "";
   userJob = "";
   userEmail = ""; 

   isUserFromSuggestions = false;
   textAreaText = ''
   tarots;
   katinas;
   images;

   userIp = '';
   result = '';

   getFortune = new FormGroup(
     {
       email: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      date_of_birth: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      relationship: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      sex: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
      ]),
      job: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
      spesific_issue: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(600),
      ]),
      hour: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
      ]),
      minute: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2),
      ]),
      birth_place: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
      ]),
     }
   );

  constructor(
    private http: ApiManagerService,
    public fb: FormBuilder,
    private notifService: NotificationsService,
    private router: Router,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute,

    private httpClient : HttpClient,
  ) { 
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])
    this.uploadForm = this.fb.group({
      avatar: [null],
      name: ['']
    })
  }

  ngOnInit() {
    this.getTellerDetail()
    this.fortuneType = localStorage.getItem('selectedFortuneType'); 
    if (localStorage.getItem('isSegueSuggestions') == 'true') {
      console.log('malirita 22: ', localStorage.getItem('isSegueSuggestions'));
      this.isUserFromSuggestions = true

      this.textAreaText = localStorage.getItem('suggestionsUserQuestion')

      if (localStorage.getItem('currentFortuneIsTarot') == 'true') {
        this.tarots =  localStorage.getItem("current_fortune_info").split(',');
        console.log("mehme 1 : ", this.tarots); 

      } else if (localStorage.getItem('currentFortuneIsTarot') == 'false') {  
       this.katinas =  localStorage.getItem("current_fortune_info").split(',');
        console.log("mehme 2 : ", this.katinas);

      }  
      if (localStorage.getItem('currentFortuneIsImage') == 'true') {
        this.images = localStorage.getItem("current_fortune_info").split(',');
        console.log("mehme 3 : ", this.images); 
      } 
    }

   
  }

  onFileChanged(event) {
    this.uploadImages.push(event.target.files);
  }

  getUserDetail() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: UserDetail = {
          token: values.token,
      user_id: values.id
    } 

    this.http.getUserDetail(data).subscribe({ 
      next: (response :any) => {
        console.log(response.result);
        if (this.tellerCredit > response.result.balance) {
          this.router.navigate(['/tellers'])
        }
        this.userDetail = response.result

        console.log("kej : ", this.userDetail);
        
        
        this.userName = this.userDetail.name
        this.userJob = this.userDetail.job
        this.userDOB = this.userDetail.birthday
        this.userEmail = this.userDetail.email

        if (this.userDetail.sex != null) {
          if (this.userDetail.sex == 1) {
            this.userGender = "Erkek"
          } else if (this.userDetail.sex == 2) {
            this.userGender = "Kadın"
          } else if (this.userDetail.sex == 3) {
            this.userGender = "LGBT"
          }
        }
        

        console.log('merhaba : ', response.result.relationship);
        

        if (response.result.relationship != undefined ||response.result.relationship != null) {
           this.userRelationship = response.result.relationship
        } else {
          this.userRelationship = 'İlişkisi Yok'
        }
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar: file
    });
    this.uploadForm.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(file)

    this.uploadImages.push(event.target.files);
  }

  onSubmitForm() {
    console.log(this.uploadForm.value)
  }


  uploadClick() { 
    this.onSendClick() 
  }


  sendFortune() {
    console.log("Mehme : ", this.userRelationship);
    
    var values = JSON.parse(localStorage.getItem("currentUser"));
        var data: FortuneHolder = {
              token: values.token,
          user_id: values.id,
          fortune_type: '1',
          name: this.getFortune.get('name').value,
          birthday: this.getFortune.get('date_of_birth').value,
          relationship: this.userRelationship,
          sex: this.gender ? this.gender : 1,
          job: this.getFortune.get('job').value,
          email: this.getFortune.get('email').value,
          question: this.getFortune.get('spesific_issue').value,
          send_to_pool: this.send_to_pool,
          is_audio: this.is_audio,
          is_express: this.is_express,
          teller_id: localStorage.getItem("selectedFortuneTeller"),
          image: this.imagesPath
       }
      console.log(data)
      this.http.sendFortune(data).subscribe({ 
        next: (response :any) => {
        console.log('response', response); 
        this.onSucces('Kahve Falınız Başarıyla Gönderilmiştir.') 
      },
      error: err => {
        if (!err) {
          console.log(' error code: ', err);  
          this.onError(err.error.error)  
        } else {
          console.log(' error : ', err);
          this.onError(err.error.error)  
        }
      }
    });
  }

  sendTarotFortune() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: TarotFortuneHolder = {
          token: values.token,
      user_id: values.id,
      fortune_type: '2',
      name: this.getFortune.get('name').value,
      birthday: this.getFortune.get('date_of_birth').value,
      relationship: this.userRelationship,
      sex: this.gender ? this.gender : 1,
      job: this.getFortune.get('job').value,
      email: this.getFortune.get('email').value,
      question: this.getFortune.get('spesific_issue').value,
      send_to_pool: this.send_to_pool,
      is_audio: this.is_audio,
      is_express: this.is_express,
      teller_id: localStorage.getItem("selectedFortuneTeller"),
      tarot_types_id: this.tarotType,
      selected_cards: `${this.tarotPaths[0]},${this.tarotPaths[1]},${this.tarotPaths[2]},${this.tarotPaths[3]},${this.tarotPaths[4]},${this.tarotPaths[5]},${this.tarotPaths[6]},${this.tarotPaths[7]},${this.tarotPaths[8]},${this.tarotPaths[9]}`
   }
   this.http.sendTarot(data).subscribe({ 
    next: (response :any) => {
      console.log('tarot-response', response);
      this.onSucces('Tarot Kartlarınız Başarıyla Gönderilmiştir.')
    },
    error: err => {
      if (!err) {
        console.log(' error : ', err); 
        this.onError(err.error.error)  
      } else {
        console.log(' error : ', err);
        this.onError(err.error.error)  
      }
    }
  });
  }

  sendKatina() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: KatinaFortuneHolder = {
          token: values.token,
      user_id: values.id,
      fortune_type: '5',
      name: this.getFortune.get('name').value,
      birthday: this.getFortune.get('date_of_birth').value,
      relationship: this.userRelationship,
      sex: this.gender ? this.gender : 1,
      job: this.getFortune.get('job').value,
      email: this.getFortune.get('email').value,
      question: this.getFortune.get('spesific_issue').value,
      send_to_pool: this.send_to_pool,
      is_audio: this.is_audio,
      is_express: this.is_express,
      teller_id: localStorage.getItem("selectedFortuneTeller"),
      tarot_types_id: this.katinaType,
      selected_cards: `${this.katinaPaths[0]},${this.katinaPaths[1]},${this.katinaPaths[2]},${this.katinaPaths[3]},${this.katinaPaths[4]},${this.katinaPaths[5]},${this.katinaPaths[6]},${this.katinaPaths[7]},${this.katinaPaths[8]},${this.katinaPaths[9]}`
   }

   this.http.sendKatina(data).subscribe({ 
    next: (response :any) => {
      console.log('katina-response', response);
      this.onSucces('Katina Kartlarınız Başarıyla Gönderilmiştir.')
    },
    error: err => {
      if (!err) {
        console.log(' error : ', err); 
        this.onError(err.error.error)  
      } else {
        console.log(' error : ', err);
        this.onError(err.error.error)  
      }
    }
  });

  }

  sendDuru() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: DuruFortuneHolder = {
          token: values.token,
      user_id: values.id,
      fortune_type: '4',
      name: this.getFortune.get('name').value,
      birthday: this.getFortune.get('date_of_birth').value,
      relationship: this.userRelationship,
      sex: this.gender ? this.gender : 1,
      job: this.getFortune.get('job').value,
      email: this.getFortune.get('email').value,
      question: this.getFortune.get('spesific_issue').value,
      send_to_pool: this.send_to_pool,
      is_audio: this.is_audio,
      is_express: this.is_express,
      teller_id: localStorage.getItem("selectedFortuneTeller"),
      image: this.imagesPath
   }
    this.http.sendDuru(data).subscribe({ 
     next: (response :any) => {
       console.log('response', response);
       this.onSucces('Duru Görü Falınız Başarıyla Gönderilmiştir.')
     },
     error: err => {
       if (!err) {
         console.log(' error : ', err); 
         this.onError(err.error.error)  
       } else {
         console.log(' error : ', err);
         this.onError(err.error.error)  
       }
     }
   });
  }

  sendYildiz() {
    this.yildizHour = this.getFortune.get('hour').value;
    this.yildizMinute = this.getFortune.get('minute').value;
    this.yildizBirthPlace = this.getFortune.get('birth_place').value;

    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: YildiznameFortuneHolder = {
          token: values.token,
      user_id: values.id,
      fortune_type: '6',
      name: this.getFortune.get('name').value,
      birthday: this.getFortune.get('date_of_birth').value,
      relationship: this.userRelationship,
      sex: this.gender ? this.gender : 1,
      job: this.getFortune.get('job').value,
      email: this.getFortune.get('email').value,
      question: this.getFortune.get('spesific_issue').value,
      send_to_pool: this.send_to_pool,
      is_audio: this.is_audio,
      is_express: this.is_express,
      teller_id: localStorage.getItem("selectedFortuneTeller"),
      birthday_city: this.yildizBirthPlace,
      city: this.yildizBirthPlace,
      birthday_hour:  `${this.yildizHour}:${this.yildizMinute}`
   }

   this.http.sendYildiz(data).subscribe({ 
    next: (response :any) => {
      console.log('response', response);
      this.onSucces('Yıldızname Falınız Başarıyla Gönderilmiştir.')
    },
    error: err => {
      if (!err) {
        console.log(' error : ', err); 
        this.onError(err.error.error)  
      } else {
        console.log(' error : ', err);
        this.onError(err.error.error)  
      }
    }
  });
  }


  getTellerDetail() {
    var tellerID = localStorage.getItem('idKey')
    console.log("memı : ", tellerID);

    this.fortuneType = localStorage.getItem("selectedFortuneType");
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: TellerDetailToken = {
            token: values.token,
        user_id: values.id,
        fortune_type_id: this.fortuneType,
        teller_id: Number(tellerID)
    } 
    this.http.getDetail(data).subscribe({ 
      next: (response :any) => {
        console.log("response : ", response.result);
        this.tellerDetail = response.result
        this.tellerCredit = response.result.credit
        this.getUserDetail()

        console.log("can express : ", response.result.can_express);
        console.log("only audiable : ", response.result.only_audiable);
        console.log("can has audiable : ", response.result.audiable);
        
        if (response.result.can_express) {
          this.tellerHasCanExpress = true
        } else {
          this.tellerHasCanExpress = false
        }

        if (response.result.only_audiable) {
          this.tellerHasOnlyAudiable = true
        } else {
          this.tellerHasOnlyAudiable = false
        }
         
        if (response.result.audiable) {
          this.tellerHasAudiable = true
        } else {
          this.tellerHasAudiable = false
        }
        
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }
  

  onSelectSubmit(value) {
    if (value === "male") {
      this.gender = 1
    } else if (value === "female") {
      this.gender = 2
    } else {
      this.gender = 3
    } 
  }

  onSubmitRelationship(value) {
    this.userRelationship = value
    console.log("MehmeAli", value); 
  }

  onSubmit() {
    if (this.getFortune.get('email').value == "") {
      this.getFortune.get('email').setValue(this.userEmail)
      console.log("user : ",this.getFortune.get('email').value);
     } 
 
     if (this.getFortune.get('name').value == "") {
      this.getFortune.get('name').setValue(this.userName)
      console.log("user : ",this.getFortune.get('name').value);
     }  
 
     if (this.getFortune.get('job').value == "") {
      this.getFortune.get('job').setValue(this.userJob)
      console.log("user : ",this.getFortune.get('job').value);
     } 
 
     if (this.getFortune.get('date_of_birth').value == "") {
      this.getFortune.get('date_of_birth').setValue(this.userDOB)
      console.log("user : ",this.getFortune.get('date_of_birth').value);
     } 
 
     if (this.getFortune.get('relationship').value == "" || this.getFortune.get('relationship').value == undefined) {
      this.getFortune.get('relationship').setValue(this.userRelationship)
      console.log("user : ",this.getFortune.get('relationship').value);
     }  
 
     if (this.getFortune.get('spesific_issue').value == "") {
       this.getFortune.get('spesific_issue').setValue(this.textAreaText)
       console.log("user : ",this.getFortune.get('spesific_issue').value);
      } 
    if (this.requiredIsTrue) {
      if (this.fortuneType === '1')  {
        this.uploadClick()
      } else if (this.fortuneType === '2') {
        this.sendTarotFortune()
      } else if (this.fortuneType === '4') {
        this.uploadClick()
      } else if (this.fortuneType === '5') {
        this.sendKatina()
      } else if (this.fortuneType === '6') {
        this.sendYildiz()
      }
    } else {
     this.onError('Kulllanıcı Sözleşmesini Kabul Etmelisiniz.')
    }
  }

  onSendToPoolChange(event) {
    if (event.target.checked) {
      this.send_to_pool = 1
      console.log("memo: " , event.checked);
    } else { return this.send_to_pool }
  } 

  onIsExpressChange(event) {
    if (event.target.checked) {
      this.is_express = 1
      console.log("memo: " , event.checked);
    } else { return this.is_express }
  }

  onChangeRequired(event) {
    if (event.target.checked) {
      this.requiredIsTrue = true
      console.log("cigor");
    } else {
      this.requiredIsTrue = false
      console.log("cigor2");
    }
  }

  onAudioChange(event) {
    if (event.target.checked) {
      this.is_audio = 1
      console.log("memo: " , event.checked)
      this.textIsChecked = true
    } else { 
       this.is_audio = 0
       this.textIsChecked = false
    }
  }

  textChange(event) {
    console.log("zerbo")
    if (event.target.checked) {
      this.audioIsChecked = true
    } else {
      this.audioIsChecked = false
    }
  } 

   onSucces(message) { 
    this.notifService.success('Fal Gönderme Başarılı',message, {
      position: ['top', 'right'],
      timeOut: 3000,
      animate: 'fade',
      showProgressBar: true
    });
    setTimeout(() => {
      this.router.navigate(['/tellers'])
    }, 6000);
  }

  onError(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 3000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  onSendClick() {  
    var values = JSON.parse(localStorage.getItem("currentUser"));
    for (let i = 0; i < this.uploadImages[0].length; i++) {
        var data: FortunePhoto = {
          photo: this.uploadImages[0][i],
              token: values.token,
          user_id: values.id
      }
      this.http.sendFortunePhoto(data).subscribe({ 
        next: (response :any) => {
          this.imagesPath.push(response.result);
          this.isOk = 1

          if (this.fortuneType == '1') {
            this.sendFortune()
          } else if (this.fortuneType == '4') {
            this.sendDuru()
          }
          console.log(response.result);
          
        },
        error: err => {
          if (!err) {
            console.log(' error photo : ', err);
            this.onError(err.error.error)  
          } else {
            console.log(' error photo 2 : ', err);
            console.log(' error description: ', err.error); 
            this.onError(err.error.error)  
          }
        }
      });
    } 
 }


 getPosition(): Promise<any>
 {
   return new Promise((resolve, reject) => {

     navigator.geolocation.getCurrentPosition(resp => {

         resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
       },
       err => {
         reject(err);
       });
   });

 }

 loadUserInfo() { 
   this.httpClient.get('https://jsonip.com')
   .pipe(
     switchMap((mapValue:any) => {
       console.log('map value : ', mapValue);
       let url = `http://api.ipstack.com/${mapValue.ip}?access_key=7c3bb24d5bda5bb0dc30c9f5b883a6ad`
       this.userIp = mapValue.ip;
       console.log('map value : ', mapValue);

       this.httpClient.get(url).subscribe((responseGet:any) => {
         console.log('malirita', responseGet.city);
         
       })

       return this.httpClient.get(url);
     })
   )
   .subscribe(
     (response : any) => { 
       console.log('ip adress = ', response); 
     },
     (error) => {
       console.log('error = ', error)
     }
   )
  }
}

