import { Component, OnInit } from '@angular/core';
import { SendFortuneComponent } from '../send-fortune/send-fortune.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-send-yildiz',
  templateUrl: './send-yildiz.component.html',
  styleUrls: ['./send-yildiz.component.scss']
})
export class SendYildizComponent implements OnInit {

  getFortune = new FormGroup(
    {
      hour: new FormControl('', [
       Validators.required,
       Validators.minLength(2),
       Validators.maxLength(2),
     ]),
     minute: new FormControl('', [
       Validators.required,
       Validators.minLength(2),
       Validators.maxLength(2),
     ]),
     birth_place: new FormControl('', [
       Validators.required,
       Validators.minLength(3),
       Validators.maxLength(20),
     ]),
    }
  );

  constructor(
    private sendFortune: SendFortuneComponent
  ) { }

  ngOnInit() { }

  onSendClicked() {
    this.sendFortune.yildizHour = this.getFortune.get('hour').value;
    this.sendFortune.yildizMinute = this.getFortune.get('minute').value;
    this.sendFortune.yildizBirthPlace = this.getFortune.get('birth_place').value;
    this.sendFortune.isOk = 1
  }
}
