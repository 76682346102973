import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './auth/signin/signin.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DuruTellesComponent } from './home/duru-telles/duru-telles.component';
import { FortuneTellersComponent } from './home/fortune-tellers/fortune-tellers.component';
import { HomeComponent } from './home/home.component';
import { KatinaTellersComponent } from './home/katina-tellers/katina-tellers.component';
import { SendFortuneComponent } from './home/send-fortune/send-fortune.component';
import { StoreComponent } from './home/store/store.component';
import { TarotTellersComponent } from './home/tarot-tellers/tarot-tellers.component';
import { TellerDetailComponent } from './home/teller-detail/teller-detail.component';
import { TellerTableComponent } from './home/teller-table/teller-table.component';
import { YildizTellersComponent } from './home/yildiz-tellers/yildiz-tellers.component';
import { ContactUsComponent } from './more-options/contact-us/contact-us.component';
import { AddCommentComponent } from './my-fortunes/add-comment/add-comment.component';
import { AddQuestionComponent } from './my-fortunes/add-question/add-question.component';
import { UpdateProfileComponent } from './user-profile/update-profile/update-profile.component';
import { UserFortuneDetailComponent } from './user-profile/user-fortune-detail/user-fortune-detail.component';


const routes: Routes = [
  { path: '', component: HomeComponent,data:{title:'Fal Evi - %100 Gerçek Falcılar ile Kahve Falı - İndir',metatags: {  'description': 'Fal Evi, %100 gerçek falcılar ile kahve falı baktırmak isteyenleri bir araya getiren mobil uygulama.'}}  },
  { path: 'teller/:id', component: TellerDetailComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'send-fortune/:id', component: SendFortuneComponent ,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'contact-us', component: ContactUsComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'update-profile', component: UpdateProfileComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'add-comment/:id', component: AddCommentComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'add-question/:id', component: AddQuestionComponent ,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'teller/:id/send-fortune/:id', component: SendFortuneComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'my-fortunes/send-fortune/:id', component: SendFortuneComponent ,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'main-signin', component: SigninComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  },
  { path: 'tellers', component: TellerTableComponent,data:{title:'Falcılar - Fal Evi - %100 Gerçek Falcılar ile Kahve Falı - İndir',metatags: {  'description': 'Yorumcular Fal Evi, %100 gerçek falcılar ile kahve falı baktırmak isteyenleri bir araya getiren mobil uygulama.'}}  },
  { path: 'forgot-password', component: ForgotPasswordComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'teller/:id/signin', component: SigninComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'fortune-detail', component: UserFortuneDetailComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'kahve-fali', component: FortuneTellersComponent,data:{title:'Fal Evi - Sesli Kahve Falı - Falınıza Alanında Uzman Gerçek Yorumcular Baksın',metatags: {  'description': 'Online kahve falınızı ister web sitesinden, ister mobil uygulama üzerinden gerçek yorumculara baktırıp, falınız geleceğe dair ne söylüyor öğrenin.'}} },
  { path: 'tarot-fali', component: TarotTellersComponent,data:{title:'Tarot Falı - Sesli Tarot Falı, Fal Evi',metatags: {  'description': 'Tarot falı üzerine uzmanlaşan yorumcularımızdan istediğinizi seçin, kartlarınız dağılsın ve falınız ne anlatıyor söylesin.'}} },
  { path: 'katina-fali', component: KatinaTellersComponent,data:{title:'Katina Falı - Aşk Falı ile Aşk Hayatınızı Öğrenin',metatags: {  'description': 'Aşk falı olarak bilinen katina falı ile aşk hayatınız ne durumda, şimdi ve gelecek için sizi neler bekliyor uzmanlarımızdan dinleyin.'}} },
  { path: 'duru-goru-fali', component: DuruTellesComponent,data:{title:'Durugörü Falı - Sesli Duru Görü Falı - Fal Evi',metatags: {  'description': 'Gerçek yorumcularımız durugörü falınıza bakmak için sizi bekliyor.'}} },
  { path: 'yildizname', component: YildizTellersComponent,data:{title:'Yıldızname Falı - Kaderiniz de ne var? Yıldızların size söyleyecekleri var - Fal Evi',metatags: {  'description': 'Yıldızname falı ile uzman yorumcularımız karakteristik bilgilerinizi kullanarak sizi nasıl bir hayatın beklediği ile kaderinizde ne olduğunu söylesin.'}} },
  { path: 'fortune-detail/send-fortune/:id', component: SendFortuneComponent ,data:{title:'Teller',metatags: {  'description': 'Teller description'}} },
  { path: 'store', component: StoreComponent,data:{title:'Teller',metatags: {  'description': 'Teller description'}}  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
