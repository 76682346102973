import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';
import { ApiManagerService } from '../home/api-manager.service';


interface TellerDetailToken {
  user_id: string,
  token: string,
  fortune_type_id: string
  teller_id: number
}

interface TellerToken {
  user_id: string,
  token: string,
  fortune_type_id: string
}

interface AnonimUser {
  device_type: string,
  device_id: string
}

@Component({
  selector: 'app-teller-slider',
  templateUrl: './teller-slider.component.html',
  styleUrls: ['./teller-slider.component.scss']
})
export class TellerSliderComponent implements OnInit {

  tellerDetail = [];
  allTellers = [];
  statusText = [];
  about_text = []; 
  loaderControllerIsHidden = 3;
  loaderController = 1;
  innerWidth : number;
  ipAddress : any;
  
  constructor(
    private http: ApiManagerService,
    private authService: AuthService,
    private https: HttpClient, 
  ) { 
    this.https.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      this.ipAddress = data
    })
  }

  ngOnInit() { 
    this.innerWidth = innerWidth;
    var values = JSON.parse(localStorage.getItem("currentUser"));
    if (values != null) { 
      this.getTellers()
    } else {
      this.createAnonyUser(); 
    } 
  } 

  getTellerDetail(tellerId: any) { 
    var values = JSON.parse(localStorage.getItem("currentUser"));
    if (values != null) {
      var data: TellerDetailToken = {
            token: values.token,
        user_id: values.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType"),
        teller_id: Number(tellerId)
      } 
      this.http.getDetail(data).subscribe({ 
        next: (response :any) => {
            this.loaderController = 0
            this.loaderControllerIsHidden = 0 
            if (response.result.status_text) {
              this.allTellers.push(response.result)
              this.statusText.push(response.result.status_text);
              this.about_text.push(response.result.about_text); 
            } 
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } else {
      var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))

      var data: TellerDetailToken = {
        token: anonyUser.result.token,
        user_id: anonyUser.result.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType"),
        teller_id: Number(tellerId)
      } 
      this.http.getDetail(data).subscribe({ 
        next: (response :any) => {
            this.loaderController = 0
            this.loaderControllerIsHidden = 0
            if (response.result.status_text) {
              this.allTellers.push(response.result) 
              this.statusText.push(response.result.status_text);
              this.about_text.push(response.result.about_text);
            } 
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } 
  }

  getTellers() { 
    var values = JSON.parse(localStorage.getItem("currentUser"));
     
    var data: TellerToken = {
        token: values.token,
        user_id: values.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType")
    } 
    this.http.getTellerList(data).subscribe({ 
      next: (response :any) => {
        //this.allTellers = response.result  
        for (let i = 0; i < response.result.length; i++) {
          const teller = response.result[i];
           this.getTellerDetail(teller.id)  
           this.loaderControllerIsHidden = 1
        }
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    }); 
    localStorage.removeItem('support_type');
  }


   getAnonyTellers() {
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))
     
    var data: TellerToken = {
        token: anonyUser.result.token,
        user_id: anonyUser.result.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType")
    } 
    this.http.getTellerList(data).subscribe({ 
      next: (response :any) => {
        //this.allTellers = response.result 
        for (let i = 0; i < response.result.length; i++) {
          const teller = response.result[i];
           this.getTellerDetail(teller.id) ;
           this.loaderControllerIsHidden = 1; 
        }
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    }); 
    localStorage.removeItem('support_type');
   } 

   createAnonyUser() {
     var anonyUser = JSON.parse(localStorage.getItem("anonyUser")) 
     if (anonyUser == null) {
        var dataF : AnonimUser = {
          device_id : `${this.ipAddress}`,
          device_type : "Web"
        }
        this.authService.signInAnony(dataF).subscribe({ 
        next: (response :any) => {
          console.log('anony token : ', response.result.token);
          console.log('anony identify : ', response.result.id);
          this.getAnonyTellers();
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
     } else {
       this.getAnonyTellers();
     } 
   }
}
