import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, DoCheck, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ifError } from 'assert';
import { AuthService } from '../auth.service'
import { ActivatedRoute, Router } from "@angular/router"
import { Title, Meta } from '@angular/platform-browser';
import { AuthService as facebookAuthService } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { delay, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

interface FacebookSignIn {
  facebook_token: string;
  device_type: string;
  device_id: string;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  private user: SocialUser;
  private loggedIn: boolean; 
  signedin$: BehaviorSubject<boolean>;
  authForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(20),
    ]),
  }
  );
  

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute,
    private facebookAuthService: facebookAuthService,
    private http: HttpClient,
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags']);
    this.signedin$ = this.authService.signedin$;}

  ngOnInit() { 
    //debugger
    this.facebookAuthService.authState.subscribe((user) => {
      this.completeFacebookLogin(user)
    }); 
  }     

  signInWithFB() {  
    //debugger
    this.facebookAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(user => {
      this.completeFacebookLogin(user)
    }).catch(error => {
      console.log('error occured = ', error)
    })
  } 
 
  signOut(): void {
    this.facebookAuthService.signOut();
  }

  onSubmit(){
    localStorage.removeItem('anonyUser');
    if (this.authForm.invalid) {
      return;
    }
    this.authService.sigin(this.authForm.value).subscribe({
      next: response => {
         this.router.navigate(['']); 
      },
      error: err => {
        if (!err.status) {
          this.authForm.setErrors({ noConnection: true });
        } else if (err.status === 500) {
          this.authForm.setErrors({ wrongEmail: true });
        }
      }
    })
  }

  onClick(){
    localStorage.removeItem('anonyUser');
    this.authService.getTellerList().subscribe({ 
      next: response => { 
          this.router.navigate(['']); 
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  } 

  completeFacebookLogin(user: SocialUser) {
    if (user) {
      console.log('user found = ', user)
      var ip = localStorage.getItem('curren_user_ip_id'); //localStorage.getItem('curren_user_ip_id');
      var device_type = 'Web';
      var fbToken: string;
      this.user = user; 
      
      var data: FacebookSignIn;
      if (user) {
        data = {
          facebook_token: user.authToken,
          device_type: device_type,
          device_id: ip
        };
      };

      if (user.authToken != null) {
        fbToken = user.authToken;
        this.authService.signInWithFacebook(data, fbToken).subscribe({
          next: response => {
  
            this.loggedIn = true;
            this.signedin$.next(true);
            this.router.navigate(['']); 
          },
          error: err => {
            if (!err.status) {
              console.log("an error occured = ", err);
            }
          }
        });
      } else {
        console.log("facebook auth token is null!!");
      };
    } else {
      console.log("an error occured please try again!!!")
    }
  }
}
