import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-my-fortunes',
  templateUrl: './my-fortunes.component.html',
  styleUrls: ['./my-fortunes.component.scss']
})
export class MyFortunesComponent implements OnInit {

  constructor(private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute) {
      
      this.titleService.setTitle(this.route.snapshot.data['title']);
      this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() {
  }

}
