import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
declare function getMobileOperatingSystem() :any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

   constructor(
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute) {
      this.titleService.setTitle(this.route.snapshot.data['title']);
      this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() {
    getMobileOperatingSystem();
  }
}
