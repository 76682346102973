import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthHttpInterceptor } from './auth/auth-http-interceptor';
import { HomeComponent } from './home/home.component';
import { TellerTableComponent } from './home/teller-table/teller-table.component';
import { TellerDetailComponent } from './home/teller-detail/teller-detail.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserTableComponent } from './user-profile/user-table/user-table.component';
import { MyFortunesComponent } from './my-fortunes/my-fortunes.component';
import { AllFortunesComponent } from './my-fortunes/all-fortunes/all-fortunes.component';
import { MoreOptionsComponent } from './more-options/more-options.component';
import { OptionsTableComponent } from './more-options/options-table/options-table.component';
import { MyMessagesComponent } from './more-options/my-messages/my-messages.component';
import { SendFortuneComponent } from './home/send-fortune/send-fortune.component';
import { SharedModule } from './shared/shared.module';
import { SendTarotComponent } from './home/send-tarot/send-tarot.component';
import { SendKatinaComponent } from './home/send-katina/send-katina.component';
import { SendYildizComponent } from './home/send-yildiz/send-yildiz.component';
import { ContactUsComponent } from './more-options/contact-us/contact-us.component';
import { UpdateProfileComponent } from './user-profile/update-profile/update-profile.component';
import { AddQuestionComponent } from './my-fortunes/add-question/add-question.component';
import { AddCommentComponent } from './my-fortunes/add-comment/add-comment.component';
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { FormsModule } from "@angular/forms";
import { OrderByPipe } from './home/order-by.pipe';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { TellerSliderComponent } from './teller-slider/teller-slider.component';
import { FirstBannerComponent } from './first-banner/first-banner.component';
import { SecondBannerComponent } from './second-banner/second-banner.component';
import { UserReviewComponent } from './user-review/user-review.component';
import { FortuneSliderComponent } from './fortune-slider/fortune-slider.component';
import { ThirtBannerComponent } from './thirt-banner/thirt-banner.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'; 
import { UserFortuneDetailComponent } from './user-profile/user-fortune-detail/user-fortune-detail.component';
import { SeletonLoaderModule } from './seleton-loader/seleton-loader.module';
import { TarotTellersComponent } from './home/tarot-tellers/tarot-tellers.component';
import { KatinaTellersComponent } from './home/katina-tellers/katina-tellers.component';
import { FortuneTellersComponent } from './home/fortune-tellers/fortune-tellers.component';
import { DuruTellesComponent } from './home/duru-telles/duru-telles.component';
import { YildizTellersComponent } from './home/yildiz-tellers/yildiz-tellers.component';
import { WebSocketService } from './web-socket.service';
import { Socket } from 'net';
import { StoreComponent } from './home/store/store.component'; 
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { FacebookLoginProvider } from "angularx-social-login";

let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("1535339816760063")
  }
]);
 
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TellerTableComponent,
    TellerDetailComponent,
    UserProfileComponent,
    UserTableComponent,
    MyFortunesComponent,
    AllFortunesComponent,
    MoreOptionsComponent,
    OptionsTableComponent,
    MyMessagesComponent,
    SendFortuneComponent,
    SendTarotComponent,
    SendKatinaComponent,
    SendYildizComponent,
    ContactUsComponent,
    UpdateProfileComponent,
    AddQuestionComponent,
    AddCommentComponent,
    OrderByPipe,
    HeaderComponent,
    FooterComponent,
    HomeHeaderComponent,
    TellerSliderComponent,
    FirstBannerComponent,
    SecondBannerComponent,
    UserReviewComponent,
    FortuneSliderComponent,
    ThirtBannerComponent,
    ForgotPasswordComponent,
    UserFortuneDetailComponent,
    TarotTellersComponent,
    KatinaTellersComponent,
    FortuneTellersComponent,
    DuruTellesComponent,
    YildizTellersComponent,
    StoreComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    SharedModule,
    Ng2SearchPipeModule,
    FormsModule,
    SimpleNotificationsModule.forRoot(),
    BrowserAnimationsModule,
    SeletonLoaderModule, 
    NgbModule,
    SocialLoginModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    //WebSocketService
    { provide: AuthServiceConfig, useFactory: provideConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
