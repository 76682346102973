import { Component, OnInit, EventEmitter } from '@angular/core';
import { NotificationsService } from 'angular2-notifications'; 
import { ApiManagerService } from 'src/app/home/api-manager.service';
import { ContactUsComponent } from 'src/app/more-options/contact-us/contact-us.component';
import {ActivatedRoute, Router} from "@angular/router" ;
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';


interface FortuneQuestion {
  user_id: string,
  token: string,
  question: string,
  fortune_id: string
}

interface Fortunes {
  status: string,
  user_id: string,
  token: string
}

interface FortuneDetail {
  user_id: string,
  token: string,
  fortune_id: string
}

interface RemoveFortune {
  user_id: string,
  token: string,
  fortune_id: number
}

interface User {
  user_id: string,
  token: string
}

interface TellerDetailToken {
  user_id: string,
  token: string,
  fortune_type_id: string
  teller_id: number
}

interface TellerCommentHolder {
  user_id: string,
  token: string,
  comment: string,
  score: number,
  fortune_id: string
}

@Component({
  selector: 'app-user-fortune-detail',
  templateUrl: './user-fortune-detail.component.html',
  styleUrls: ['./user-fortune-detail.component.scss'],
  providers: [ContactUsComponent]
})
export class UserFortuneDetailComponent implements OnInit { 
  fortuneStat = '';
  allFortunes = [];
  fortuneId = '';
  fortuneDetail = '';
  images = [];
  tarotPaths = [];
  dummyPaths = [];
  path: string;
  paths=[];
  mp3Path = [];
  imgPath = [];
  id: any;
  suggestions = []
  userAmount;
  routerLink: string | any;

  isPathActive = false;
  isSelectedCardsActive = false;
  isImagesActive = false;
  isAudioActive = false;
  isTextCommentActive = false
  
  fortuneType : any;
  code : any;
  tellerDetail = "";
  tellerImage: '';

  closeResult = '';
  modalType = 0
  comment_score;
  isSendedOne = false
  isSendedTwo = false

  //UserDetail
  userGender = "";
  commentDate = "";
  userAge = "";
  userJob = "";
  userRelationship = "";
  spesificIssue = "";

  getUserComment = new FormGroup(
    {
      userComment: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]), 
      comment: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(30),
      ]), 
    }
  );

  constructor(
    private apiManager: ApiManagerService,
    private notifService: NotificationsService,
    private support: ContactUsComponent,
    private router: Router, 
    private modalService: NgbModal,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() { 
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser")) 
    console.log('anony : ', anonyUser); 
    
    if (anonyUser != null) { 
      this.router.navigate([''])
    } else {
      this.getUser();
      localStorage.removeItem('support_type');  
    }

    localStorage.removeItem('isSegueSuggestions');
    localStorage.removeItem('current_fortune_info');
    localStorage.removeItem('currentFortuneIsTarot');
    localStorage.removeItem('currentFortuneIsImage');
  } 

  onClick(value: string) {
    this.fortuneStat = value;
    this.fortuneDetail = '';
    this.ngOnInit();
  }

  onClickDetail(value: string) {
  
  }

  onAddCommentClicked(event) {
    localStorage.removeItem("teller_id_add_comment")
    this.id = event;
    localStorage.setItem("teller_id_add_comment" ,this.id);
    console.log(this.id);
  } 


  getFortuneDetail() { 
    var response = JSON.parse(localStorage.getItem("selected_fortune_detail_1"));  
    console.log('malirita : ', response);
    
    if (response.path == null) {
      console.log('null');
    } else if (response.path != null) {
      this.paths = response.path.split(',')
      for (let i = 0; i < this.paths.length; i++) {
        if (this.paths[i].includes("mp3")) {  
        } else {
          console.log("berzo",this.paths[i]);
          if (this.paths[i] != "undefined") {
            this.imgPath.push(this.paths[i])
            this.isPathActive = true
          
          } 
        }
      }
    } 

    if ( response.comment.audio != null ) {
      this.mp3Path = response.comment.audio
      this.isAudioActive = true;
    }

    if (response.selected_cards != null) {
      if (response.selected_cards.length == 0) {
        console.log('null');
      } else if (response.selected_cards != null) {
        this.dummyPaths = response.selected_cards.split(',');
        this.isSelectedCardsActive = true
        for (let i = 0; i <  this.dummyPaths.length; i++) {
           if (this.dummyPaths[i] != "undefined") { 
              this.tarotPaths.push(this.dummyPaths[i])  
           } 
        } 
      }
    } 

    if (response.images != null) {
      if (response.images.length == 0) {
        console.log('null');
      } else if (response.images != null) {
        this.images = response.images;
        this.isImagesActive = true
      }
    }  

    if (response.comment.audio != null) {
      this.isAudioActive = true
    }

    if (response.comment.text != null) {
      this.isTextCommentActive = true
    } 

    if (response.info.sex == 1) {
      this.userGender = "Erkek"
    } else if (response.info.sex == 2) {
      this.userGender = "Kadın"
    } else {
      this.userGender = "LGBT"
    }
     
    this.commentDate = response.comment.date;
    this.userAge = response.info.birthday;
    this.userJob = response.info.job;
    this.userRelationship = response.info.relationship;
    this.spesificIssue = response.info.question

    this.fortuneDetail = response; 
    this.suggestions = response.suggestions;
    this.getTellerDetail(response.fortune_teller.id, response.fortune_type_id);
    localStorage.setItem('selectedFortuneType', response.fortune_type_id);    

    if (this.isPathActive || this.isSelectedCardsActive) {
      localStorage.setItem('current_fortune_info' , `${this.dummyPaths}`);
      localStorage.setItem('suggestionsUserQuestion', response.info.question)
      if (this.isPathActive) {
         localStorage.setItem('currentFortuneIsTarot', `${true}`)
      } else {
        localStorage.setItem('currentFortuneIsTarot', `${false}`)
      }
    } else if (this.isImagesActive){  
      localStorage.setItem('suggestionsUserQuestion', response.info.question)
      localStorage.setItem('current_fortune_info' , `${this.images}`);
      localStorage.setItem('currentFortuneIsImage', `${true}`)
    } 
  }

  getTellerDetail(teller_id, fortune_type) { 
      var values = JSON.parse(localStorage.getItem("currentUser")); 
      var data: TellerDetailToken = {
              token: values.token,
          user_id: values.id,
          fortune_type_id: fortune_type,
          teller_id: teller_id
      }  

      this.apiManager.getDetail(data).subscribe({ 
        next: (response :any) => {
          this.tellerDetail = response.result 
          this.tellerImage = response.result.image 
          console.log("response : ", response.result);
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      }); 
     
  }

  onRemoveFortuneClicked(event) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: RemoveFortune = {
            token: values.token,
        user_id: values.id,
        fortune_id: event
    }

    this.apiManager.removeFortune(data).subscribe({ 
      next: (response :any) => {
        console.log('remove fortune', response.result);
        this.ngOnInit();
        this.onSuccesAllFortunes('Falınız başarılı bir şekilde silindi')
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  getUser() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: User = {
            token: values.token,
        user_id: values.id,
    } 

    this.apiManager.getUserDetail(data).subscribe({
      next: (response) => {
        this.userAmount = response.result.balance;
        console.log(response.result.balance);
        this.getFortuneDetail();
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSendFortuneClicked(event: any, eventSecond: any) {
    if (this.userAmount >= eventSecond) {
      this.routerLink = ['send-fortune/', event];
      console.log('USER_AMOUNT');
      localStorage.setItem('selectedFortuneTeller', event);
    } else if (eventSecond > this.userAmount) {
      console.log('TELLER_CREDIT');
      this.onErrorAllFortunes('Altınınız Yetersiz')
      this.routerLink = [''];
    }
  }

  onSupportFortuneClicked(event) {
    localStorage.setItem('support_type', '3')
    console.log(event);
  }


  onSuccesAllFortunes(message) {
    this.notifService.success('Tamamdır!!',message, {
      position: ['top', 'right'],
      timeOut: 3000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  onErrorAllFortunes(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  counter(i: number) {
    return new Array(i);
  }  

  open(content, modalType) {
    this.modalType = modalType
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  onSendClicked(status) { 
    if (this.modalType == 1) {
      if (this.getUserComment.get('userComment').value != "") {
        this.onClickSend()
      } 
    } else if (this.modalType == 2) {
      if (this.getUserComment.get('comment').value != "") {
        this.onClickSendAddComment()
      }
    }
   
  }

  onClickSend() {
    var response = JSON.parse(localStorage.getItem("selected_fortune_detail_1"));  
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: FortuneQuestion = {
            token: values.token,
        user_id: values.id,
        fortune_id: response.id,
        question: this.getUserComment.get('userComment').value
    }

    this.apiManager.addQuestions(data).subscribe({ 
      next: (response :any) => {
        console.log(response.result) 
        this.modalService.dismissAll()
        this.onSuccesAllFortunes('Sorunuz başarıyla gönderildi.')
        this.isSendedOne = true
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
          this.modalService.dismissAll()
          this.onErrorAllFortunes(err.error.error)
          this.isSendedOne = true
        } else {
          this.modalService.dismissAll()
          this.onErrorAllFortunes(err.error.error)
          console.log(' error : ', err);
          this.isSendedOne = true
        }
      }
    });
  }

  onSelectSubmit(value) {
    this.comment_score = value
  }

  onClickSendAddComment() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: TellerCommentHolder = {
            token: values.token,
        user_id: values.id,
        fortune_id: localStorage.getItem("teller_id_add_comment"),
        comment: this.getUserComment.get('comment').value,
        score: this.comment_score
    }

    this.apiManager.addComment(data).subscribe({ 
      next: (response :any) => {
        console.log(response.result)
        this.modalService.dismissAll()
        this.onSuccesAllFortunes('Yorumunuz başarıyla gönderildi.')
        this.isSendedTwo = true
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
          this.modalService.dismissAll()
          this.onErrorAllFortunes(err.error.error)
          this.isSendedTwo = true
        } else {
          console.log(' error : ', err);
          this.modalService.dismissAll()
          this.onErrorAllFortunes(err.error.error)
          this.isSendedTwo = true
        }
      }
    });
  }

  onSuggestionsSendFortuneClicked(event, eventSecond, eventThirt) {
    localStorage.setItem('isSegueSuggestions', `${true}`)
    var response = JSON.parse(localStorage.getItem("selected_fortune_detail_1"));  
    if (this.userAmount >= eventSecond) {
      this.routerLink = ['send-fortune/', event];
      console.log('USER_AMOUNT');
      
      console.log('user selected fortune teller : ', eventThirt.value);
      localStorage.setItem('selectedFortuneType', response.fortuneDetail)
      localStorage.setItem('selectedFortuneTeller', event);
    } else if (eventSecond > this.userAmount) {
      console.log('TELLER_CREDIT');
      this.onErrorAllFortunes('Altınınız Yetersiz') 
    }
  }
}
