import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'falevi';
  signedin$: BehaviorSubject<boolean>;
  anonySignedIn: BehaviorSubject<boolean>;

  constructor(private authService: AuthService) {
    this.signedin$ = this.authService.signedin$;
    this.anonySignedIn = this.authService.anonySignedIn$
  }

  ngOnInit() {
   this.authService.checkAuth().subscribe(() => {});
   //this.authService.checkAnony().subscribe(() => {});
  }
}
