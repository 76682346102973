import { ValueTransformer } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { ApiManagerService } from '../api-manager.service';
import { SendFortuneComponent } from '../send-fortune/send-fortune.component';

interface TarotCards {
  user_id: string,
  token: string
}

@Component({
  selector: 'app-send-tarot',
  templateUrl: './send-tarot.component.html',
  styleUrls: ['./send-tarot.component.scss']
})

export class SendTarotComponent implements OnInit {

  tarotCards = [];
  tarotPahts = [];
  tarotType : number;
  card_id = [];
  isOk = false;
  allCardsLenght : number;
  selectedTypeCardCount = []; 
  selected_id = [];
  allcard_id = [];

  toggle = true;
  status = 'Enable'; 

  constructor(
    private apiManagerService: ApiManagerService,
    private sendFortune : SendFortuneComponent,
    private notifService: NotificationsService
  ) { }

  ngOnInit() {
    
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: TarotCards = {
      user_id : values.id,
      token : values.token
    }

    this.apiManagerService.getTarotCards(data).subscribe({ 
      next: (response :any) => {
        this.tarotCards = response.result;
        this.allCardsLenght = this.tarotCards.length
        for (let i = 0; i < this.tarotCards.length; i++) {
           this.allcard_id.push(this.tarotCards[i].id)
        }
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSubmitType(value) {
    if (value === 'kelt') {
      this.tarotType = 3
      this.sendFortune.tarotType = 3
      this.selectedTypeCardCount.length = 10
    } else if (value === 'iliski') {
      this.tarotType = 5
      this.sendFortune.tarotType = 5
      this.selectedTypeCardCount.length = 7
    } else if (value === 'karar') {
      this.tarotType = 1
      this.sendFortune.tarotType = 1
      this.selectedTypeCardCount.length = 7
    }
  } 

  onCardClicked(value, event, id, target) {   
    this.selected_id.push(value)
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
    if (this.card_id.indexOf(event) <= -1 ) {
      if (this.tarotType === 3) {
        if (this.tarotPahts.length === 10 || this.card_id.length === 10) {
          console.log("lenght is full");
          console.log(this.tarotPahts);
          this.sendFortune.isOk = 1
          this.isOk = true
          this.onError('En fazla 10 kart seçebilirsiniz.')
        } else {
          console.log("lengt is not full");
          this.tarotPahts.push(`${value}`);
          this.sendFortune.tarotPaths.push(`${value}`);
          this.card_id.push(event);
          this.selectedTypeCardCount.length -= 1
       }
    } else if (this.tarotType === 1 || this.tarotType === 5 || this.card_id.length === 10) {
        if (this.tarotPahts.length === 7) {
          console.log("lenght is full");
          console.log(this.tarotPahts);
          this.sendFortune.isOk = 1
          this.isOk = true
          this.onError('En fazla 10 kart Seçebilirsiniz.')
        } else {
          console.log("lengt is not full");
          this.tarotPahts.push(`${value}`);
          this.sendFortune.tarotPaths.push(`${value}`);
          this.card_id.push(event);
          this.selectedTypeCardCount.length -= 1
        }
      }
    } else {
        this.selectedTypeCardCount.length += 1
        const index: number = this.card_id.indexOf(event);
        const indexSecond: number = this.tarotPahts.indexOf(value); 
        this.card_id.splice(index, 1);
        this.tarotPahts.splice(indexSecond, 1);
        this.sendFortune.tarotPaths.splice(indexSecond, 1);
        this.onError('Katlarınızı seçerken lütfen dikkatli seçiniz.')
    } 
  }

  onError(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 3000,
      animate: 'fade',
      showProgressBar: true
    });
  }
}
