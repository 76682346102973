import { Component, OnInit, Input, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { ApiManagerService } from '../api-manager.service';

interface TellerDetailToken {
  user_id: string,
  token: string,
  fortune_type_id: string
  teller_id: number
}

interface AddToFavs {
  user_id: string,
  token: string,
  teller_id: string
}

interface User {
  user_id: string,
  token: string
}

interface TellerToken {
  user_id: string,
  token: string,
  fortune_type_id: string
}

@Component({
  selector: 'app-teller-detail',
  templateUrl: './teller-detail.component.html',
  styleUrls: ['./teller-detail.component.scss']
})


@Injectable({
  providedIn: 'root'
})

export class TellerDetailComponent implements OnInit {
  code: any;
  fortuneType;
  tellerDetail='';
  tellerImage: '';
  tellerScores=[];
  userAmount;
  routerLink: string | any;
  fortune :any = 1;

  fortuneTypesArr = ["1","2","5","4","6"];
  statusOneTellers = [];
  statusTwoTellers = [];
  statusFourTellers = [];
  statusFifeTellers = [];
  statusSixTellers = [];

  fortuneCredit = 0
  tarotCredit = 0
  katinaCredit = 0
  duruCredit = 0
  yildizCredit = 0

  path = ""

  @Input() isFortuneActive : boolean;
  @Input() isTarotActive : boolean;
  @Input() isKatinaActive : boolean;
  @Input() isDuruActive : boolean;
  @Input() isYildizActive : boolean;
  
  @Input() tellerID : Number;

  fortuneIsEnable = false;
  tarotIsEnable = false;
  katinaIsEnable = false;
  duruGoruIsEnable = false;
  yildiznameIsEnable = false;

  currentResponseMinute = ""
  currentCommentCount = ""
  currentPoint = ""

  dumyScore = "Bu Fal Türü Yorumcu İçin Aktif Değildir."
  isCommentInactive = false
  isUserAnony = false
  loaderController = 0;

  constructor(  
    private route: ActivatedRoute,
    private apiManagerService: ApiManagerService,
    private notifService: NotificationsService,
    private titleService:Title,
    private MetaService:Meta
  ) {
    this.titleService.setTitle(this.route.snapshot.data['teller']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])}

  ngOnInit() {

    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))

   
    if (anonyUser != null) {
      console.log('kdsfngknfsdg: ', anonyUser.result.token);
      this.fortuneIsEnable = true
      setTimeout(() => {
       this.route.paramMap.subscribe( paramMap => {
         this.code = paramMap.get('id');
       })
       this.fortuneType = localStorage.getItem("selectedFortuneType");
   
       var data: TellerDetailToken = {
           token: anonyUser.result.token,
           user_id: anonyUser.result.id,
           fortune_type_id: this.fortuneType,
           teller_id: this.code
       } 
   
       this.apiManagerService.getDetail(data).subscribe({ 
         next: (response :any) => {
           this.tellerDetail = response.result
           this.titleService.setTitle("Fal Evi - " + response.result.name)
           this.MetaService.updateTag(this.route.snapshot.data[response.result.about_text])
           this.getTellerGlobalFortuneStatus(response.result.id)
           this.tellerID = response.result.id
           this.tellerImage = response.result.image
           this.isUserAnony = true
           this.loaderController = 3
           if (response.result.online_status = "online") {
              this.path = './assets/img/profile-bg.svg'
           } else if (response.result.online_status = "busy") {
             this.path = './assets/img/profile-bg-yellow.svg'
           } else {
             this.path = './assets/img/profile-bg-red.svg'
           }
         },
         error: err => {
           if (!err) {
             console.log(' error : ', err);
           } else {
             console.log(' error : ', err);
           }
         }
       });
       this.getComments();
       //this.getUser();
      }, 300);
    } else {
      
      this.fortuneIsEnable = true
      setTimeout(() => {
       this.route.paramMap.subscribe( paramMap => {
         this.code = paramMap.get('id');
       })
       this.fortuneType = localStorage.getItem("selectedFortuneType"); 
       var values = JSON.parse(localStorage.getItem("currentUser")); 
       var data: TellerDetailToken = {
               token: values.token,
           user_id: values.id,
           fortune_type_id: this.fortuneType,
           teller_id: this.code
       } 
   
       this.apiManagerService.getDetail(data).subscribe({ 
         next: (response :any) => {
           this.tellerDetail = response.result
           this.titleService.setTitle("Fal Evi - " + response.result.name)
           this.MetaService.updateTag(this.route.snapshot.data[response.result.about_text])
           this.getTellerGlobalFortuneStatus(response.result.id)
           this.tellerID = response.result.id
           this.tellerImage = response.result.image
           this.isUserAnony = false
           this.loaderController = 3
           console.log('response result : ',  response.result);
           
       
           if (response.result.online_status = "online") {
              this.path = './assets/img/profile-bg.svg'
           } else if (response.result.online_status = "busy") {
             this.path = './assets/img/profile-bg-yellow.svg'
           } else {
             this.path = './assets/img/profile-bg-red.svg'
           }
         },
         error: err => {
           if (!err) {
             console.log(' error : ', err);
           } else {
             console.log(' error : ', err);
           }
         }
       });
       this.getComments();
       this.getUser();
      }, 300);
    }
  }

  getComments() {
    this.loaderController = 0
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))

    if (anonyUser != null) {
      this.route.paramMap.subscribe( paramMap => {
        this.code = paramMap.get('id');
      })
      this.fortuneType = localStorage.getItem("selectedFortuneType");
  
      var values = JSON.parse(localStorage.getItem("currentUser"));
      var data: TellerDetailToken = {
          token: anonyUser.result.token,
          user_id: anonyUser.result.id,
          fortune_type_id: this.fortuneType,
          teller_id: this.code
      } 
  
      this.apiManagerService.getDetail(data).subscribe({ 
        next: (response :any) => {
          this.tellerScores = response.result.scores     
          this.currentResponseMinute = response.result.response_minute
          this.currentCommentCount = response.result.comments_count
          this.currentPoint = response.result.point
          this.loaderController = 3
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } else {
      this.route.paramMap.subscribe( paramMap => {
        this.code = paramMap.get('id');
      })
      this.fortuneType = localStorage.getItem("selectedFortuneType");
  
      var values = JSON.parse(localStorage.getItem("currentUser"));
      var data: TellerDetailToken = {
              token: values.token,
          user_id: values.id,
          fortune_type_id: this.fortuneType,
          teller_id: this.code
      } 
  
      this.apiManagerService.getDetail(data).subscribe({ 
        next: (response :any) => {
          this.tellerScores = response.result.scores     
          this.currentResponseMinute = response.result.response_minute
          this.currentCommentCount = response.result.comments_count
          this.currentPoint = response.result.point
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    }
  }

  getScoreHandle(fortuneType) {

    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))

    if (anonyUser != null) {
      
      this.route.paramMap.subscribe( paramMap => {
        this.code = paramMap.get('id');
      })
     
      var values = JSON.parse(localStorage.getItem("currentUser"));
      var data: TellerDetailToken = {
          token: anonyUser.result.token,
          user_id: anonyUser.result.id,
          fortune_type_id: fortuneType,
          teller_id: this.code
      } 
  
      this.apiManagerService.getDetail(data).subscribe({ 
        next: (response :any) => {
          this.tellerScores = response.result.scores
          this.currentResponseMinute = response.result.response_minute
          this.currentCommentCount = response.result.comments_count
          this.currentPoint = response.result.point
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } else {
      this.route.paramMap.subscribe( paramMap => {
        this.code = paramMap.get('id');
      })
     
      var values = JSON.parse(localStorage.getItem("currentUser"));
      var data: TellerDetailToken = {
              token: values.token,
          user_id: values.id,
          fortune_type_id: fortuneType,
          teller_id: this.code
      } 
  
      this.apiManagerService.getDetail(data).subscribe({ 
        next: (response :any) => {
          this.tellerScores = response.result.scores
          this.currentResponseMinute = response.result.response_minute
          this.currentCommentCount = response.result.comments_count
          this.currentPoint = response.result.point
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    }
  } 

  getUser() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: User = {
            token: values.token,
        user_id: values.id,
    } 

    this.apiManagerService.getUserDetail(data).subscribe({
      next: (response) => {
        this.userAmount = response.result.balance;
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }


  onFavsButtonClicked(value) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data : AddToFavs = {
      user_id: values.id,
          token: values.token,
      teller_id: value
    }

    this.apiManagerService.addToFavs(data).subscribe({ 
      next: (response :any) => {
        console.log('add favs', response.result);
        this.ngOnInit()
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onRemoveFavsButtonClicked(value) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data : AddToFavs = {
      user_id: values.id,
          token: values.token,
      teller_id: value
    }

    this.apiManagerService.removeFromFavs(data).subscribe({ 
      next: (response :any) => {
        console.log('remove favs', response.result);
        this.ngOnInit()
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onSendFortuneClicked(event, eventSecond, eventThirt) {
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))
    if (anonyUser) {
      this.routerLink = ['/signin'];
    } else {
      if (this.userAmount >= eventSecond) {
        this.routerLink = ['send-fortune/', event];
        console.log('USER_AMOUNT'); 
        console.log('user selected fortune teller : ', eventThirt.value);
        localStorage.setItem('selectedFortuneType', eventThirt)
        localStorage.setItem('selectedFortuneTeller', event);
      } else if (eventSecond > this.userAmount) {
        console.log('TELLER_CREDIT');
        this.onError('Altınınız Yetersiz') 
      }
    } 
  }

  onError(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  onFortuneTypeClick(event: any) {
    this.fortune = event;
    localStorage.setItem('selectedFortuneType', this.fortune);
  }

  counter(i: number) {
    return new Array(i);
  } 

  getTellerGlobalFortuneStatus(tellerId: any) {
    let tellerID = tellerId
    this.fortuneTypesArr.forEach(fortuneType => {
        if (fortuneType == "1") {
          this.getGlobalTellers(fortuneType, tellerID)
        }
        if (fortuneType == "2") {
          this.getGlobalTellers(fortuneType, tellerID)
        } 
        if (fortuneType == "5") {
          this.getGlobalTellers(fortuneType, tellerID)
        } 
        if (fortuneType == "4") {
          this.getGlobalTellers(fortuneType, tellerID)
        } 
        if (fortuneType == "6") {
          this.getGlobalTellers(fortuneType, tellerID)
        }
    })
  }
  

  getGlobalTellers(fortuneType: string, tellerID: any) {
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))

    if (anonyUser != null) {
      var values = JSON.parse(localStorage.getItem("currentUser"));
      var data: TellerToken = {
          token: anonyUser.result.token,
          user_id: anonyUser.result.id,
          fortune_type_id: fortuneType
      } 
      this.apiManagerService.getTellerList(data).subscribe({ 
        next: (response :any) => {
          if (fortuneType == "1") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusOneTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.fortuneCredit = response.result[i].credit
                }
              }
              if (this.statusOneTellers.includes(tellerID)) {
                this.isFortuneActive = true
              }
              //console.log(this.statusOneTellers);
          } 
          if (fortuneType == "2") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusTwoTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.tarotCredit = response.result[i].credit
                }
              }
              if (this.statusTwoTellers.includes(tellerID)) {
                this.isTarotActive = true
              }
              //console.log(this.statusTwoTellers);
          } 
          if (fortuneType == "5") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusFifeTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.katinaCredit = response.result[i].credit
                }
              }
              if (this.statusFifeTellers.includes(tellerID)) {
                this.isKatinaActive = true
              }
              //console.log(this.statusFifeTellers);
          } 
          if (fortuneType == "4") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusFourTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.duruCredit = response.result[i].credit
                }
              }
              if (this.statusFourTellers.includes(tellerID)) {
                this.isDuruActive = true
              }
              //console.log(this.statusFourTellers);
          } 
          if (fortuneType == "6"){
              for (let i = 0; i < response.result.length; i++) {
                this.statusSixTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.yildizCredit = response.result[i].credit
                }
              }
              if (this.statusSixTellers.includes(tellerID)) {
                this.isYildizActive = true
              }
              //console.log(this.statusSixTellers);
          }
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } else {
      var values = JSON.parse(localStorage.getItem("currentUser"));
      var data: TellerToken = {
              token: values.token,
          user_id: values.id,
          fortune_type_id: fortuneType
      } 
      this.apiManagerService.getTellerList(data).subscribe({ 
        next: (response :any) => {
          if (fortuneType == "1") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusOneTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.fortuneCredit = response.result[i].credit
                }
              }
              if (this.statusOneTellers.includes(tellerID)) {
                this.isFortuneActive = true
              }
              //console.log(this.statusOneTellers);
          } 
          if (fortuneType == "2") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusTwoTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.tarotCredit = response.result[i].credit
                }
              }
              if (this.statusTwoTellers.includes(tellerID)) {
                this.isTarotActive = true
              }
              //console.log(this.statusTwoTellers);
          } 
          if (fortuneType == "5") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusFifeTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.katinaCredit = response.result[i].credit
                }
              }
              if (this.statusFifeTellers.includes(tellerID)) {
                this.isKatinaActive = true
              }
              //console.log(this.statusFifeTellers);
          } 
          if (fortuneType == "4") {
              for (let i = 0; i < response.result.length; i++) {
                this.statusFourTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.duruCredit = response.result[i].credit
                }
              }
              if (this.statusFourTellers.includes(tellerID)) {
                this.isDuruActive = true
              }
              //console.log(this.statusFourTellers);
          } 
          if (fortuneType == "6"){
              for (let i = 0; i < response.result.length; i++) {
                this.statusSixTellers.push(response.result[i].id)
                if (response.result[i].id == this.tellerID) {
                  this.yildizCredit = response.result[i].credit
                }
              }
              if (this.statusSixTellers.includes(tellerID)) {
                this.isYildizActive = true
              }
              //console.log(this.statusSixTellers);
          }
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    }
  }

  onInactiveClicked(value) {
    this.isCommentInactive = true
    if (value == 1) {
      this.fortuneIsEnable = true
      this.tarotIsEnable = false
      this.katinaIsEnable = false
      this.duruGoruIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 2) {
      this.tarotIsEnable = true
      this.fortuneIsEnable = false
      this.katinaIsEnable = false
      this.duruGoruIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 5) {
      this.katinaIsEnable = true
      this.tarotIsEnable = false
      this.fortuneIsEnable = false
      this.duruGoruIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 4) {
      this.duruGoruIsEnable = true
      this.tarotIsEnable = false
      this.fortuneIsEnable = false
      this.katinaIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 6) {
      this.yildiznameIsEnable = true
      this.tarotIsEnable = false
      this.fortuneIsEnable = false
      this.katinaIsEnable = false
      this.duruGoruIsEnable = false
    }
  }

  getScoreStatus(value) {
    this.isCommentInactive = false
    if (value == 1) {
      this.fortuneIsEnable = true
      this.tarotIsEnable = false
      this.katinaIsEnable = false
      this.duruGoruIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 2) {
      this.tarotIsEnable = true
      this.fortuneIsEnable = false
      this.katinaIsEnable = false
      this.duruGoruIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 5) {
      this.katinaIsEnable = true
      this.tarotIsEnable = false
      this.fortuneIsEnable = false
      this.duruGoruIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 4) {
      this.duruGoruIsEnable = true
      this.tarotIsEnable = false
      this.fortuneIsEnable = false
      this.katinaIsEnable = false
      this.yildiznameIsEnable = false
    } else if (value == 6) {
      this.yildiznameIsEnable = true
      this.tarotIsEnable = false
      this.fortuneIsEnable = false
      this.katinaIsEnable = false
      this.duruGoruIsEnable = false
    }

    this.getScoreHandle(value)
  }
}
