import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DealerPaymentServiceDirectPaymentResultData, DealerPaymentServicePaymentRequest, PaymentDetailData, productList, WebPostverifyRequest } from 'src/models/paymentModels';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  rootUrl = 'https://service.moka.com/PaymentDealer/';
  rootUrlBackendApi = 'https://api2.falevi.com.tr';
  IpAdress :string; 
  constructor(private http: HttpClient) { }


  /*getStore3dControl(credentials){
    let resultdata = new PaymentDetailData()
    
    return fetch(`${this.rootUrl}GetDealerPaymentTrxDetailList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    })
    .then((response) => response.json())
    //Then with the data from the response in JSON...
    .then((data) => {
      if(data['Data'] != null ){
        resultdata= data['Data'];
        resultdata.ResultMessage = data['Exception'];
        resultdata.ResultCode = "Success"
      }
      else{
        resultdata =null
        resultdata.ResultMessage = "Limit Exceeded";
        resultdata.ResultCode = "Limiti Aşıldı";
      }
      return resultdata
    })
    
    .catch((error) => {
      resultdata = null
      resultdata.ResultMessage = error;
      if (error['ResultCode'].toString() =="PaymentDealer.CheckPaymentDealerAuthentication.InvalidRequest")      
      {
        resultdata.ResultCode = "Hatalı hash bilgisi";
      }
      else if (error['ResultCode'].toString() == "PaymentDealer.CheckPaymentDealerAuthentication.InvalidAccount")
      {
        resultdata.ResultCode = "Böyle bir bayi bulunamadı";
      }
      else if (error['ResultCode'].toString() == "PaymentDealer.CheckPaymentDealerAuthentication.VirtualPosNotFound")
      {
        resultdata.ResultCode = "Bu bayi için sanal pos tanımı yapılmamış";
      }
      else if (error['ResultCode'].toString() =="PaymentDealer.CheckDealerPaymentLimits.DailyDealerLimitExceeded")
      {
        resultdata.ResultCode = "Bayi için tanımlı günlük limitlerden herhangi biri aşıldı";
      }
      else if (error['ResultCode'].toString() =="PaymentDealer.CheckDealerPaymentLimits.DailyCardLimitExceeded")
      {
        resultdata.ResultCode = "Gün içinde bu kart kullanılarak daha fazla işlem yapılamaz";
      }
      
      else if (error['ResultCode'].toString() =="PaymentDealer.CheckCardInfo.InvalidCardInfo")
      {
        resultdata.ResultCode = "Kart bilgilerinde hata var";
      }
      
      else if (error['ResultCode'].toString() =="PaymentDealer.DoDirectPayment.ThreeDRequired")
      {
        resultdata.ResultCode = "3d zorunlu";
      }
      
      else if (error['ResultCode'].toString() =="PaymentDealer.DoDirectPayment.InstallmentNotAvailableForForeignCurrencyTransaction")
      {
        resultdata.ResultCode = "Yabancı para ile taksit yapılamaz";
      }
      else if (error['ResultCode'].toString() =="PaymentDealer.DoDirectPayment.ThisInstallmentNumberNotAvailableForDealer")
      {
        resultdata.ResultCode = "Bu taksit sayısı bu bayi için yapılamaz";
      }
      else if (error['ResultCode'].toString() =="PaymentDealer.DoDirectPayment.InvalidInstallmentNumber")
      {
        resultdata.ResultCode = "Taksit sayısı 2 ile 9 arasıdır";
      }
      else if (error['ResultCode'].toString() =="PaymentDealer.DoDirectPayment.ThisInstallmentNumberNotAvailableForVirtualPos")
      {
        resultdata.ResultCode = "Sanal Pos bu taksit sayısına izin vermiyor";
      }
      else (error['ResultCode'].toString() =="EX")
      {
        resultdata.ResultCode = "Beklenmeyen bir hata oluştu";
      }
      return resultdata
    })
  }
*/
  

  PostWebPosVerify(request:WebPostverifyRequest){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams()
      .set('orderId', request.orderId.toString())
      .set('price', request.price.toString())
      .set('status', request.status)
      .set('user_id', request.userId.toString())
      .set('product', request.product.toString())
      .set('created_at', request.created_at.toString())
      .set('token', request.token.toString());

    console.log('request params detail => ', params);
    
    return this.http.post(`${this.rootUrlBackendApi}/webpos/verify`, params).pipe(
      tap( response => {
         if (response) {
           console.log('response is not empty => ', response);
         } else {
           console.log('response is empty');
         }
      })
    );
	}


  getStore3DSecure(credentials: DealerPaymentServicePaymentRequest){
    
    let ip = '';
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{ 
      ip = res.ip
    });
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    credentials.PaymentDealerRequest.ClientIP = ip
		return  this.http.post(`${this.rootUrl}DoDirectPaymentThreeD`, credentials, { headers: headers }).toPromise().then((data:DealerPaymentServiceDirectPaymentResultData)=>{
      let resultdata = new DealerPaymentServiceDirectPaymentResultData()
      if(data.Data != null ){
        resultdata.Data= data.Data;
        resultdata.Exception = data.Exception;
        resultdata.ResultCode = "Success"
      }
      else{
        resultdata.Data = null
        resultdata.ResultCode = data.ResultCode;
        switch (data.ResultCode) {
          case "PaymentDealer.CheckPaymentDealerAuthentication.InvalidRequest":
            resultdata.Exception = "Hatalı hash bilgisi";
            break;
          case "PaymentDealer.CheckPaymentDealerAuthentication.InvalidAccount":
              resultdata.Exception = "Böyle bir bayi bulunamadı";
              break;
          case "PaymentDealer.CheckPaymentDealerAuthentication.VirtualPosNotFound":
              resultdata.Exception = "Bu bayi için sanal pos tanımı yapılmamış";
              break;
          case "PaymentDealer.CheckDealerPaymentLimits.DailyDealerLimitExceeded":
              resultdata.Exception = "Bayi için tanımlı günlük limitlerden herhangi biri aşıldı";
              break;
          case "PaymentDealer.CheckDealerPaymentLimits.DailyCardLimitExceeded":
              resultdata.Exception = "Gün içinde bu kart kullanılarak daha fazla işlem yapılamaz";
              break;
          case "PaymentDealer.CheckCardInfo.InvalidCardInfo":
              resultdata.Exception = "Kart bilgilerinde hata var";
              break;
          case "PaymentDealer.DoDirectPayment.ThreeDRequired":
              resultdata.Exception = "3d zorunlu";
              break;
          case "PaymentDealer.DoDirectPayment.InstallmentNotAvailableForForeignCurrencyTransaction":
              resultdata.Exception = "Yabancı para ile taksit yapılamaz";
              break;
          case "PaymentDealer.DoDirectPayment.ThisInstallmentNumberNotAvailableForDealer":
                resultdata.Exception = "Bu taksit sayısı bu bayi için yapılamaz";
                break;
          case "PaymentDealer.DoDirectPayment.InvalidInstallmentNumber":
                resultdata.Exception = "Taksit sayısı 2 ile 9 arasıdır";
                break;
          case "PaymentDealer.DoDirectPayment.ThisInstallmentNumberNotAvailableForVirtualPos":
                resultdata.Exception = "Sanal Pos bu taksit sayısına izin vermiyor";
                break;
                                                    
          default:
            resultdata.Exception = "Beklenmeyen bir hata oluştu"; break;
      }
      
        }
      return resultdata
    })
  }

  
  getStoreNot3DSecure(credentials: DealerPaymentServicePaymentRequest){
    
    let ip = '';
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{ 
      ip = res.ip
    });
    credentials.PaymentDealerRequest.ClientIP = ip

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
		return  this.http.post(`${this.rootUrl}DoDirectPayment`, credentials, { headers: headers }).toPromise().then((data:DealerPaymentServiceDirectPaymentResultData)=>{
      let resultdata = new DealerPaymentServiceDirectPaymentResultData()
      if(data.Data != null ){
        resultdata.Data= data.Data;
        resultdata.Exception = data.Exception;
        resultdata.ResultCode = "Success"
      }

      resultdata.Data = null
        resultdata.ResultCode = data.ResultCode;
        switch (data.ResultCode) {
          case "PaymentDealer.CheckPaymentDealerAuthentication.InvalidRequest":
            resultdata.Exception = "Hatalı hash bilgisi";
            break;
          case "PaymentDealer.CheckPaymentDealerAuthentication.InvalidAccount":
              resultdata.Exception = "Böyle bir bayi bulunamadı";
              break;
          case "PaymentDealer.CheckPaymentDealerAuthentication.VirtualPosNotFound":
              resultdata.Exception = "Bu bayi için sanal pos tanımı yapılmamış";
              break;
          case "PaymentDealer.CheckDealerPaymentLimits.DailyDealerLimitExceeded":
              resultdata.Exception = "Bayi için tanımlı günlük limitlerden herhangi biri aşıldı";
              break;
          case "PaymentDealer.CheckDealerPaymentLimits.DailyCardLimitExceeded":
              resultdata.Exception = "Gün içinde bu kart kullanılarak daha fazla işlem yapılamaz"; break;
          case "PaymentDealer.CheckCardInfo.InvalidCardInfo":
              resultdata.Exception = "Kart bilgilerinde hata var"; break;
          case "PaymentDealer.DoDirectPayment.ThreeDRequired":
              resultdata.Exception = "3d zorunlu"; break;
          case "PaymentDealer.DoDirectPayment.InstallmentNotAvailableForForeignCurrencyTransaction":
              resultdata.Exception = "Yabancı para ile taksit yapılamaz"; break;
          case "PaymentDealer.DoDirectPayment.ThisInstallmentNumberNotAvailableForDealer":
                resultdata.Exception = "Bu taksit sayısı bu bayi için yapılamaz"; break;
          case "PaymentDealer.DoDirectPayment.InvalidInstallmentNumber":
                resultdata.Exception = "Taksit sayısı 2 ile 9 arasıdır"; break;
          case "PaymentDealer.DoDirectPayment.ThisInstallmentNumberNotAvailableForVirtualPos":
                resultdata.Exception = "Sanal Pos bu taksit sayısına izin vermiyor"; break;
                                                    
          default:
            resultdata.Exception = "Beklenmeyen bir hata oluştu"; break;
        }
      return resultdata
    })
  }

  
  getStore3dControl(credentials){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
		return  this.http.post(`${this.rootUrl}GetDealerPaymentTrxDetailList`, credentials, { headers: headers }).toPromise().then((data:any)=>{
      let resultdata = new PaymentDetailData()
      if(data.Data.IsSuccessful != false ){
        resultdata.IsSuccessful= true;
        resultdata.PaymentDetail = data.Data.PaymentDetail;
        resultdata.PaymentTrxDetailList = data.Data.PaymentTrxDetailList;
        resultdata.ResultCode = data.Data.ResultCode;
        resultdata.ResultMessage =data.Data.ResultMessage
      }
      else{
        resultdata.IsSuccessful = false
        resultdata.ResultCode = data.ResultCode;
        switch (data.ResultCode) {
          case "PaymentDealer.CheckPaymentDealerAuthentication.InvalidRequest":
            resultdata.ResultMessage = "Hatalı hash bilgisi"; break;
          case "PaymentDealer.CheckPaymentDealerAuthentication.InvalidAccount":
              resultdata.ResultMessage = "Böyle bir bayi bulunamadı"; break;
          default:
            resultdata.ResultMessage = "Beklenmeyen bir hata oluştu"; break;
        }
      }
        
      return resultdata
    })
  }


  getProductDetail(request:productList){
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json'); //let jsonParser = JSON.parse(localStorage.getItem("currentUser")); 
    let params =new HttpParams().
    set("token",request.token)
    .set("user_id",request.user_id)
    
		return  this.http.get(`${this.rootUrlBackendApi}/webpos/webproducts`, { headers: headers,params:params }).toPromise();
  }
}
