import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fortune-slider',
  templateUrl: './fortune-slider.component.html',
  styleUrls: ['./fortune-slider.component.scss']
})
export class FortuneSliderComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  
  onFortuneButtonTapped(actionType: number) {
    switch (actionType) {
        case 1:
          this.router.navigate(['/kahve-fali'])
          break
        case 2:
          this.router.navigate(['/tarot-fali'])
          break
        case 3: 
          this.router.navigate(['/katina-fali'])
          break
        case 4:
          this.router.navigate(['/duru-goru-fali']) 
          break
        case 5: 
          this.router.navigate(['/yildizname']) 
          break
    }
  }
}
