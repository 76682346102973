import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';  
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class WebSocketService {

  socket : any;

  constructor() {
    /*
    this.socket = io.io('http://api.falevi.com.tr:3001?member_id=550409&token=$2y$10$uqGO.W1OAZQT0EL7jEkUSu1Z3U6RoTXTufgRplWZQvbrniYRVXqGi&fortune_teller_id=3&fortune_type_id=1');
    */ 
   } 

  //teller_id, fortune_type_id, user_id ve user_token bilgileri alınıp socket io' ya gönderilmesi lazım.
  listen(eventName: string) {
    /*
     return new Observable((subscriber) => { 
      this.socket.on(eventName, (data: any) => { 
        console.log('data connected')
        subscriber.next(data);
      })
    }); 
     */
   
  } 

  emit(eventName: string, data: any) { 
    /*
     this.socket.emit(eventName, data); 
    console.log('emitted : ', data);
    */ 
  } 
 

  disconnect() { 
    /*
     if (this.socket != null) { 
      return new Observable((subscriber) => { 
        this.socket.on('disconnect', (data: any) => { 
          console.log('data disconnected')
          subscriber.next(data);
        })
      }); 
      this.socket = null;

    } else {
      console.log('socket is not null');
    } 
    */
   
  } 
}
