import { HttpClient } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';
import { ApiManagerService } from '../home/api-manager.service';


interface TellerDetailToken {
  user_id: string,
  token: string,
  fortune_type_id: string
  teller_id: number
}

interface TellerToken {
  user_id: string,
  token: string,
  fortune_type_id: string
}

interface AnonimUser {
  device_type: string,
  device_id: string
}

interface TellerDetailResponse {
  code: number,
  result: {
    about_text: string,
    audiable: Boolean,
    can_express: Boolean,
    comments_count: Boolean,
    credit: number,
    duru_online_status: string,
    express_credit: number,
    favourite_count: number,
    fortune_online_status: string,
    id: number,
    image: string,
    is_active: Boolean,
    is_favourited: Boolean,
    katina_online_status: string,
    last_login: string,
    membership_date: string,
    name: string,
    notify_me: Boolean,
    online_status: string,
    only_audiable: true,
    pending_count: number,
    point: number,
    response_minute: number,
    scores: [string],
    star: number,
    status_text: string,
    tarot_online_status: string,
    user_type: string,
    yildiz_name_online_status: string
  }
}

@Component({
  selector: 'app-user-review',
  templateUrl: './user-review.component.html',
  styleUrls: ['./user-review.component.scss']
})
export class UserReviewComponent implements OnInit {

  @ViewChild('para', { static: true }) para: ElementRef;
  tellerDetail = [];
  allTellers = [];
  statusText = [];
  about_text = [];
  ipAddress : any; 
  loaderControllerIsHidden = 3;
  loaderController = 1;
  isTellerScroresEmpty = false

  constructor(
    private http: ApiManagerService,
    private authService: AuthService,
    private https: HttpClient, 
  ) { 
    this.https.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      this.ipAddress = data
    })
  }

  ngOnInit() {   
    var values = JSON.parse(localStorage.getItem("currentUser")); 
    if (values == null) { 
      console.log("anony isn't  null"); 
      this.getAnonyTellers() 
    } else {
      this.getTellers() 
    } 
  }


  getTellerDetail(tellerId: any) {  
    if (localStorage.getItem("selectedFortuneType")) {
      localStorage.setItem("1","selectedFortuneType")
    } 
    var lastValue = [];
    var pushOnce = false;
    var values = JSON.parse(localStorage.getItem("currentUser")); 
    if (values != null) {
      var data: TellerDetailToken = {
            token: values.token,
        user_id: values.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType"),
        teller_id: Number(tellerId)
      } 
      this.http.getDetail(data).subscribe({ 
        next: (response :TellerDetailResponse) => {  
          if (response.result.scores) {
            this.allTellers.push(response.result)  
            this.statusText.push(response.result.status_text);
            this.about_text.push(response.result.about_text); 
            this.loaderController = 0
            this.loaderControllerIsHidden = 0 
          }

          if (response.result.scores.length > 0) {
            this.isTellerScroresEmpty = false
          } else {
            this.isTellerScroresEmpty = true
          }
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } else { 
      var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))
      var data: TellerDetailToken = {
        token: anonyUser.result.token,
        user_id:  anonyUser.result.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType"),
        teller_id: Number(tellerId)
      } 

      this.http.getDetail(data).subscribe({ 
        next: (response :TellerDetailResponse) => {  
          if (response.result.scores) { 
            this.allTellers.push(response.result)  
            this.statusText.push(response.result.status_text);
            this.about_text.push(response.result.about_text); 
            this.loaderController = 0
            this.loaderControllerIsHidden = 0 
          }
        
          if (response.result.scores.length > 0) {
            this.isTellerScroresEmpty = false
          } else {
            this.isTellerScroresEmpty = true
          }
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
    } 
  }

  getTellers() {  
    var values = JSON.parse(localStorage.getItem("currentUser"));
    if (values != null) { 
      
      var data: TellerToken = {
            token: values.token,
        user_id: values.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType")
      } 
      this.http.getTellerList(data).subscribe({ 
        next: (response :any) => {
          //this.allTellers = response.result 
          for (let i = 0; i < response.result.length; i++) {
            const teller = response.result[i];
            this.getTellerDetail(teller.id) 
            
           this.loaderControllerIsHidden = 1 
           this.loaderControllerIsHidden = 1; 
          }
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      }); 
    } else {
      var anonyUser = JSON.parse(localStorage.getItem("anonyUser")) 
      
      var data: TellerToken = {
        token: anonyUser.result.token,
        user_id: anonyUser.result.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType")
      } 
      this.http.getTellerList(data).subscribe({ 
        next: (response :any) => {
          //this.allTellers = response.result 
          for (let i = 0; i < response.result.length; i++) {
            const teller = response.result[i];
            this.getTellerDetail(teller.id) 
            
           this.loaderControllerIsHidden = 1 
           this.loaderControllerIsHidden = 1; 
          }
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });  
    }
    localStorage.removeItem('support_type');
  }


  getAnonyTellers() {
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))   
    if (anonyUser == null) {
      this.createAnonyUser()
    } else {
      var data: TellerToken = {
        token: anonyUser.result.token,
        user_id: anonyUser.result.id,
        fortune_type_id: localStorage.getItem("selectedFortuneType")
    } 
    this.http.getTellerList(data).subscribe({ 
      next: (response :any) => { 
        //this.allTellers = response.result 
        this.loaderControllerIsHidden = 1;  
        
        for (let i = 0; i < response.result.length; i++) {
          const teller = response.result[i];
           this.getTellerDetail(teller.id)   
        }
      },
      error: err => {
        if (!err) { 
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    }); 
    } 
    localStorage.removeItem('support_type');
   } 

   createAnonyUser() {
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser")) 
    if (anonyUser == null) {
       var dataF : AnonimUser = {
         device_id : `${this.ipAddress}`,
         device_type : "Web"
       }
       this.authService.signInAnony(dataF).subscribe({ 
       next: (response :any) => {
         console.log('anony token : ', response.result.token);
         console.log('anony identify : ', response.result.id); 
         this.loaderControllerIsHidden = 1; 
         this.getAnonyTellers();
       },
       error: err => {
         if (!err) {
           console.log(' error : ', err);
         } else {
           console.log(' error : ', err);
         }
       }
     });
    } else {
      this.getAnonyTellers();
    } 
  } 
}
