
export class DealerPaymentServicePaymentRequest{
    PaymentDealerAuthentication:PaymentDealerAuthentication;
    PaymentDealerRequest:PaymentDealerRequest
    
    constructor() {
    }
}

export class PaymentDealerAuthentication{
    DealerCode:string;
    Username:string;
    Password:string;
    CheckKey:string;
    
    constructor() {
    }
}

export class PaymentDealerRequest
{
    CardHolderFullName :string
    CardNumber :string
    ExpMonth:string 
    ExpYear :string
    CvcNumber:string
    Amount: number
    Currency: string
    InstallmentNumber:number
    VirtualPosOrderId: string
    VoidRefundReason: number
    ClientIP :string
    RedirectUrl:string
    UtilityCompanyBillId :number
    DealerStaffId :number
    BuyerInformation:BuyerInformation
    OtherTrxCode :string
    IsPreAuth :number
    RedirectType:number
    constructor() {
        
    }
}

export class BuyerInformation{
    BuyerFullName:string
    BuyerEmail:string
    BuyerGsmNumber:string
    BuyerAddress:string
}
 
export class DealerPaymentServiceDirectPaymentResult
{
    Data: DealerPaymentServiceDirectPaymentResultData;
    ResultCode:string;
    ResultMessage:string;
    Exception :string
    constructor() {
    }
}
 
export class DealerPaymentServiceDirectPaymentResultData 
{
    ResultCode :string
    ResultMessage :string
    VirtualPosOrderId:string
    Data:string
    Exception:string
    constructor() {
    }
}

export class Not3dSecureResponse{
    data:any
}

export class PaymentDetail {
    DealerPaymentId: number;
    OtherTrxCode: string;
    CardHolderFullName: string;
    CardNumberFirstSix: string;
    CardNumberLastFour: string;
    PaymentDate: Date;
    Amount: number;
    CurrencyCode: string;
    InstallmentNumber: number;
    DealerCommissionAmount: number;
    IsThreeD: boolean;
    PaymentStatus: number;
    TrxStatus: number;
}

export class PaymentTrxDetailList {
    DealerPaymentTrxId: number;
    DealerPaymentId: number;
    TrxCode: string;
    TrxDate: Date;
    TrxType: number;
    TrxStatus: number;
    PaymentReason: number;
    VoidRefundReason: number;
    VirtualPosOrderId: string;
    ResultMessage: string;
}

export class PaymentDetailData {
    IsSuccessful: boolean;
    ResultCode: string;
    ResultMessage: string;
    PaymentDetail: PaymentDetail;
    ListItemCount: number;
    PaymentTrxDetailList: PaymentTrxDetailList[];
}

export class RootObject {
    Data: PaymentDetailData;
    ResultCode: string;
    ResultMessage: string;
    Exception?: any;
}

export class PaymentRequestModel{
    UserId :number
    token:string
    gold:number  
}

export class WebPostverifyRequest{
    token:string;
    orderId:string;
    userId:number;
    status:string;
    price:number;
    product:number;
    created_at:string
}

export class productList{
    token:string
    user_id:string
}