import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { MatchPassword } from '../validators/match-password';
import { AuthService } from '../auth.service'
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router'; 

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  userIp = '';
  city = '';

  authForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      Validators.pattern(/^[a-z0-9]+$/)
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(20),
    ]),
    passwordConfirmation: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(20),
    ])
  },
  { validators: [this.matchPassword.validate] }
  );

  constructor(
    private matchPassword: MatchPassword,
    private authService: AuthService, 
    private httpClient : HttpClient,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute, 
    private router: Router,
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])}

  ngOnInit() {
    this.loadUserInfo();
  }

  onSubmit(){
    if (this.authForm.invalid) {
      return;
    }
    
    this.authService.signup(this.authForm.value).subscribe({
      next: response => {
        this.router.navigate([''])
      },
      error: err => {
        if (!err.status) {
          this.authForm.setErrors({ noConnection: true })
        } else if (err.status === 422) {
          this.authForm.setErrors({ nonUniqueEmail: true })
        }
      }
    })
  }

  loadUserInfo() { 
    this.httpClient.get('https://jsonip.com')
    .pipe(
      switchMap((mapValue:any) => {
        console.log('map value : ', mapValue);
        let url = `http://api.ipstack.com/${mapValue.ip}?access_key=7c3bb24d5bda5bb0dc30c9f5b883a6ad`
        this.userIp = mapValue.ip;
        console.log('map value : ', mapValue);
 
        this.httpClient.get(url).subscribe((responseGet:any) => {
          localStorage.removeItem('current_user_city')
          console.log('malirita', responseGet.city); 
          localStorage.setItem('current_user_city', responseGet.city)
          this.city = responseGet.city;
        })
 
        return this.httpClient.get(url);
      })
    )
    .subscribe(
      (response : any) => { 
        console.log('ip adress = ', response.ip); 
        localStorage.setItem('curren_user_ip_id', response.ip)
      },
      (error) => {
        console.log('error = ', error)
      }
    )
  }

}
