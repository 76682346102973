import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ApiManagerService } from 'src/app/home/api-manager.service';
import { log } from 'util';
import { AllFortunesComponent } from '../all-fortunes/all-fortunes.component';

interface TellerCommentHolder {
  user_id: string,
  token: string,
  comment: string,
  score: number,
  fortune_id: string
}

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {

 @Input() teller_id : any;
 comment_score: number;

  getComment = new FormGroup(
    {
      comment: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
      ]),
    }
  );

  constructor(
    private apiManager: ApiManagerService,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags']) }

  ngOnInit() {
    
  }

  onSelectSubmit(value) {
    this.comment_score = value
  }

  onClickSend() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: TellerCommentHolder = {
            token: values.token,
        user_id: values.id,
        fortune_id: localStorage.getItem("teller_id_add_comment"),
        comment: this.getComment.get('comment').value,
        score: this.comment_score
    }

    this.apiManager.addComment(data).subscribe({ 
      next: (response :any) => {
        console.log(response.result)
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }
}
