import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { ApiManagerService } from '../api-manager.service';
import { NotificationsService } from 'angular2-notifications';
import { BehaviorSubject, from } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute, Data } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TellerDetailComponent } from '../teller-detail/teller-detail.component';
import { WebSocketService } from '../../web-socket.service';
import { PassThrough } from 'stream';
import { Title, Meta } from '@angular/platform-browser';

interface TellerToken {
  user_id: string,
  token: string,
  fortune_type_id: string
}

interface FavouriteTellers {
  user_id: string,
  token: string,
  fortune_type_id: string
}

interface User {
  user_id: string,
  token: string
}

interface AddToFavs {
  user_id: string,
  token: string,
  teller_id: string
}

interface AnonimUser {
  device_type: string,
  device_id: string
}

@Component({ 
  selector: 'app-teller-table',
  templateUrl: './teller-table.component.html',
  styleUrls: ['./teller-table.component.scss'],
})

export class TellerTableComponent implements OnInit {
  [x: string]: any;
   tellers = [];
   fortuneType = '';
   viewController = false;
   favouriteTellers = [];
   search;
   filter_tellers = [];
   controller;
   char;
   charController;
   tableController;
   userAmount;
   routerLink: string | any;
   activeTellers = [];
   signedin$: BehaviorSubject<boolean>;
   ipAddress: any;
   isEnded = false;
   anonyToken = "nil";
   userIdentifier = 0;
   anonySignedIn$: BehaviorSubject<boolean>;

   fortuneTypesArr = ["1","2","5","4","6"];
   statusOneTellers = [];
   statusTwoTellers = [];
   statusFourTellers = [];
   statusFifeTellers = [];
   statusSixTellers = [];

   loaderController = 0;

   title = "socket-io-example"

   transform(value, args:string[]) : any {
    let res = [];
    for (let i = 0; i < value; i++) {
        res.push(i);
      }
      return res;
  }

  counter(i: number) {
    return new Array(i);
  } 

  constructor(
    private apiManagerService: ApiManagerService,
    private notifService: NotificationsService,
    private authService: AuthService,
    private http: HttpClient,
    private mainTellerDetail: TellerDetailComponent,
    private webSocketService: WebSocketService,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])
    this.signedin$ = this.authService.signedin$; 
    this.anonySignedIn$ = this.authService.anonySignedIn$

    this.http.get<{ip:string}>('https://jsonip.com')
    .subscribe( data => {
      this.ipAddress = data
    })
  }

  ngOnInit() {  
    //this.webSocketService.disconnect()
    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))
    if (!this.signedin$.value) {
      if (anonyUser == null) {
      var dataF : AnonimUser = {
        device_id : `${this.ipAddress}`,
        device_type : "Web"
      }
      this.authService.signInAnony(dataF).subscribe({ 
        next: (response :any) => {
          console.log('anony token : ', response.result.token);
          console.log('anony identify : ', response.result.id);
          
          this.anonyToken = response.result.token
          this.userIdentifier = response.result.id
  
          
          this.getAllTellers()
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
       });
      } 
      
    }

    var anonyUser = JSON.parse(localStorage.getItem("anonyUser"))
   if (anonyUser != null) {
      if (anonyUser.result.token == null) {
        if (!this.isEnded) {
          var dataF : AnonimUser = {
            device_id : `${this.ipAddress}`,
            device_type : "Web"
        }
        this.authService.signInAnony(dataF).subscribe({ 
          next: (response :any) => {
            console.log('anony token : ', response.result.token);
            console.log('anony identify : ', response.result.id);
            
            this.anonyToken = response.result.token
            this.userIdentifier = response.result.id
    
            this.getAllTellers()
          },
          error: err => {
            if (!err) {
              console.log(' error : ', err);
            } else {
              console.log(' error : ', err);
            }
          }
        });
        this.anonySignedIn$.next(true)
        this.isEnded = true
        }

      } else {
        this.getAllTellers()
      }
   } else {
    if (this.signedin$.value) {
      this.anonySignedIn$.next(true)
     this.tableController = 1
     if (!this.fortuneType) {
       this.fortuneType = '1'
       localStorage.setItem('selectedFortuneType', this.fortuneType);
     } else { }
     var values = JSON.parse(localStorage.getItem("currentUser"));
     console.log('aşdskjfnas :', values);
     
     var data: TellerToken = {
             token: values.token,
         user_id: values.id,
         fortune_type_id: this.fortuneType
     } 
     this.apiManagerService.getTellerList(data).subscribe({ 
       next: (response :any) => {
         this.tellers = response.result;
         this.loaderController = 1
         console.log("berzah : ", this.loaderController);
       },
       error: err => {
         if (!err) {
           console.log(' error : ', err);
         } else {
           console.log(' error : ', err);
         }
       }
     });
     this.getUser();
     localStorage.removeItem('support_type');
    }
   }
  }

   

  getAllTellers() {
    var values = JSON.parse(localStorage.getItem("anonyUser"));
    if (values != null) {
      if (!this.fortuneType) {
        this.fortuneType = '1'
        localStorage.setItem('selectedFortuneType', this.fortuneType);
      } else { }
      this.tableController = 1
      var data: TellerToken = {
          token: values.result.token,
          user_id: values.result.id,
          fortune_type_id: this.fortuneType
      } 
      this.anonyToken = values.result.token
      this.apiManagerService.getTellerList(data).subscribe({ 
        next: (response :any) => {
          this.tellers = response.result;
          this.loaderController = 1
          
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
      localStorage.removeItem('support_type');
    } else {
      var user = JSON.parse(localStorage.getItem("currentUser"));
      if (!this.fortuneType) {
        this.fortuneType = '1'
        localStorage.setItem('selectedFortuneType', this.fortuneType);
      } else { }
      this.tableController = 1
      var data: TellerToken = {
          token: user.token,
          user_id: user.id,
          fortune_type_id: this.fortuneType
      } 
      this.apiManagerService.getTellerList(data).subscribe({ 
        next: (response :any) => {
          this.tellers = response.result;
        },
        error: err => {
          if (!err) {
            console.log(' error : ', err);
          } else {
            console.log(' error : ', err);
          }
        }
      });
      localStorage.removeItem('support_type');
    }
    
  }

  onClick(typeId: string) {
    if (!this.anonySignedIn$) {
      this.fortuneType = typeId
      localStorage.setItem('selectedFortuneType', this.fortuneType);
      this.ngOnInit()
      console.log("deneme : ", this.fortuneType);
    } else {
      this.fortuneType = typeId
      localStorage.setItem('selectedFortuneType', this.fortuneType);
      this.getAllTellers()
      console.log("deneme : ", this.fortuneType);
    }

  }


  onTrClick(value: number) {
    console.log('value', value);
    this.viewController = !this.viewController
  }

  ontryClicked(val: String) {
    console.log("memo  :", val);
    
  }

  onClickFavOrTeller(value: number) {
    if (value === 1) {
       this.ngOnInit()
    } else {
      this.getFavsTeller()
    }
  }

  onSubmitSelect(event) {
    if (event == 'audio-true') {
      this.filter_tellers = [];
      for (let i = 0; i < this.tellers.length; i++) {
        if (this.tellers[i].audiable == true) {   
          console.log(this.tellers[i]);
          this.filter_tellers.push(this.tellers[i]);
          this.controller = 1;
          this.tableController = 2;
          this.charController = 0;
        }
      }  
    } else if (event == 'audio-false'){
      this.filter_tellers = [];
      for (let i = 0; i < this.tellers.length; i++) {
        if (this.tellers[i].audiable == false) {   
          console.log(this.tellers[i]);
          this.filter_tellers.push(this.tellers[i]);
          this.controller = 1;
          this.tableController = 2;
          this.charController = 0;
        }
      }  
    } else if (event == 'active') {
      for (let i = 0; i < this.tellers.length; i++) {
        if (this.tellers[i].is_active == true) {   
          this.activeTellers.push(this.tellers[i]);
          this.filter_tellers = this.activeTellers;
          this.controller = 1;
          this.tableController = 2;
          this.charController = 0;
        }
      }  
    } else if (event == 'rich-true') {
          this.char = 'credit';
          this.controller = 1;
          this.charController = 1;
          this.tableController = 3
    } else if (event == 'poor-false') {
          this.char = 'credit';
          this.controller = 1;
          this.charController = 0;
          this.tableController = 3
    } else if (event == 'high-true') {
          this.char = 'point';
          this.controller = 1;
          this.charController = 1;
          this.tableController = 3
    } else if (event == 'low-false') {
          this.char = 'point';
          this.controller = 1;
          this.charController = 0;
          this.tableController = 3
    }
  }

  getFavsTeller() {
    if (!this.fortuneType) {
      this.fortuneType = '1'
      localStorage.setItem('selectedFortuneType', this.fortuneType);
    } else {
      
    }
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: FavouriteTellers = {
            token: values.token,
        user_id: values.id,
        fortune_type_id: this.fortuneType
    } 
    this.apiManagerService.getFavsTeller(data).subscribe({ 
      next: (response :any) => {
        this.tellers = response.result;
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }


  onSendFortuneClicked(event: any, eventSecond: any) {
    if (this.userAmount >= eventSecond) {
      this.routerLink = ['send-fortune/', event];
      console.log('USER_AMOUNT');
      localStorage.setItem('selectedFortuneTeller', event);
    } else if (eventSecond > this.userAmount) {
      console.log('TELLER_CREDIT');
      this.onError('Altınınız Yetersiz')
      this.routerLink = [''];
    }
  }

   key: string  = 'credit';
   reverse:boolean = false;
   sort(key) {
     this.key = key;
     this.reverse = !this.reverse;
   }

   sortTellers(prop: string) {
    if (this.filter_tellers.length != 0) {
      if (this.charController == 0) {
        const sorted = this.filter_tellers.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
        if (prop.charAt(0) === '-') { sorted.reverse(); }
        return sorted;
      } else if (this.charController == 1) {
        const sorted = this.filter_tellers.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
        if (prop.charAt(0) === '-') { sorted; }
        return sorted;
      }
    } else if (this.filter_tellers.length < 1) {
      if (this.charController == 0) {
        const sorted = this.tellers.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
        if (prop.charAt(0) === '-') { sorted.reverse(); }
        return sorted;
      } else if (this.charController == 1) {
        const sorted = this.tellers.sort((a, b) => a[prop] < b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
        if (prop.charAt(0) === '-') { sorted; }
        return sorted;
      }
    }
  }

  getUser() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: User = {
            token: values.token,
        user_id: values.id,
    } 

    this.apiManagerService.getUserDetail(data).subscribe({
      next: (response) => {
        this.userAmount = response.result.balance;
        console.log(response.result.balance);
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onFavsButtonClicked(event) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data : AddToFavs = {
      user_id: values.id,
          token: values.token,
      teller_id: event
    }

    this.apiManagerService.addToFavs(data).subscribe({ 
      next: (response :any) => {
        console.log('add favs', response.result);
        this.ngOnInit()
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onRemoveFavsButtonClicked(event) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data : AddToFavs = {
      user_id: values.id,
          token: values.token,
      teller_id: event
    }

    this.apiManagerService.removeFromFavs(data).subscribe({ 
      next: (response :any) => {
        console.log('remove favs', response.result);
        this.ngOnInit()
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  onError(message) {
    this.notifService.error('Opps!',message, {
      position: ['top', 'right'],
      timeOut: 2000,
      animate: 'fade',
      showProgressBar: true
    });
  }

  getTellerGlobalFortuneStatus(tellerID: any) {
    localStorage.removeItem('idKey')
    
    localStorage.setItem('idKey', tellerID)
  }
  

  getGlobalTellers(fortuneType: string, tellerID: any) {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    var data: TellerToken = {
            token: values.token,
        user_id: values.id,
        fortune_type_id: fortuneType
    } 
    this.apiManagerService.getTellerList(data).subscribe({ 
      next: (response :any) => {
        if (fortuneType == "1") {
            for (let i = 0; i < response.result.length; i++) {
              this.statusOneTellers.push(response.result[i].id)
            }
            if (this.statusOneTellers.includes(tellerID)) {
              this.mainTellerDetail.isFortuneActive = true
              console.log("içeriyor 1");
            } else {
              console.log("içermiyor 1");
            }
            //console.log(this.statusOneTellers);
        } 
        if (fortuneType == "2") {
            for (let i = 0; i < response.result.length; i++) {
              this.statusTwoTellers.push(response.result[i].id)
            }
            if (this.statusTwoTellers.includes(tellerID)) {
              this.mainTellerDetail.isTarotActive = true
              console.log("içeriyor 2");
            } else {
              console.log("içermiyor 2");
            }
            //console.log(this.statusTwoTellers);
        } 
        if (fortuneType == "5") {
            for (let i = 0; i < response.result.length; i++) {
              this.statusFifeTellers.push(response.result[i].id)
            }
            if (this.statusFifeTellers.includes(tellerID)) {
              this.mainTellerDetail.isKatinaActive = true
              console.log("içeriyor 5");
            } else {
              console.log("içermiyor 5");
            }
            //console.log(this.statusFifeTellers);
        } 
        if (fortuneType == "4") {
            for (let i = 0; i < response.result.length; i++) {
              this.statusFourTellers.push(response.result[i].id)
            }
            if (this.statusFourTellers.includes(tellerID)) {
              this.mainTellerDetail.isDuruActive = true
              console.log("içeriyor 4");
            } else {
              console.log("içermiyor 4");
            }
            //console.log(this.statusFourTellers);
        } 
        if (fortuneType == "6"){
            for (let i = 0; i < response.result.length; i++) {
              this.statusSixTellers.push(response.result[i].id)
            }
            if (this.statusSixTellers.includes(tellerID)) {
              this.mainTellerDetail.isYildizActive = true
              console.log("içeriyor 6");
            } else {
              console.log("içermiyor 6");
            }
            //console.log(this.statusSixTellers);
        }
      },
      error: err => {
        if (!err) {
          console.log(' error : ', err);
        } else {
          console.log(' error : ', err);
        }
      }
    });
  }

  configureWebSocket() {
    /*
    this.webSocketService.listen('connect').subscribe((data) => {
      this.webSocketService.emit('acks', data)
      console.log('data from socket listener : ', data);  
    });
    */ 
  } 
}