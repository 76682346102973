import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title = 'falevi';
  signedin$: BehaviorSubject<boolean>;

  isFirstClick = true;
  isSecondClick = false;
  isThirtClick = false;
  isFourtClick = false;
  isFifthClick = false;
  isSixthClick = false;
  isSeventhClick = false;

  constructor(private authService: AuthService) {
    this.signedin$ = this.authService.signedin$;
  }

  ngOnInit() {
   this.authService.checkAuth().subscribe(() => {});
  }

  menuOnClick(value: any) {
    console.log(' mwnu : ', value);
    
    if (value == 1) {
      this.isFirstClick = true;
      this.isSecondClick = false;
      this.isThirtClick = false;
      this.isFourtClick = false;
      this.isFifthClick = false;
      this.isSixthClick = false;
      this.isSeventhClick = false;
    } else if (value == 2) {
      this.isFirstClick = false;
      this.isSecondClick = true;
      this.isThirtClick = false;
      this.isFourtClick = false;
      this.isFifthClick = false;
      this.isSixthClick = false;
      this.isSeventhClick = false;
    } else if (value == 3) {
      this.isFirstClick = false;
      this.isSecondClick = false;
      this.isThirtClick = true;
      this.isFourtClick = false;
      this.isFifthClick = false;
      this.isSixthClick = false;
      this.isSeventhClick = false;
    } else if (value == 4) {
      this.isFirstClick = false;
      this.isSecondClick = false;
      this.isThirtClick = false;
      this.isFourtClick = true;
      this.isFifthClick = false;
      this.isSixthClick = false;
      this.isSeventhClick = false;
    } else if (value == 5) {
      this.isFirstClick = false;
      this.isSecondClick = false;
      this.isThirtClick = false;
      this.isFourtClick = false;
      this.isFifthClick = true;
      this.isSixthClick = false;
      this.isSeventhClick = false;
    } else if (value == 6) {
      this.isFirstClick = false;
      this.isSecondClick = false;
      this.isThirtClick = false;
      this.isFourtClick = false;
      this.isFifthClick = false;
      this.isSixthClick = true;
      this.isSeventhClick = false;
    } else if (value == 7) {
      this.isFirstClick = false;
      this.isSecondClick = false;
      this.isThirtClick = false;
      this.isFourtClick = false;
      this.isFifthClick = false;
      this.isSixthClick = false;
      this.isSeventhClick = true;
    }
  }
}
