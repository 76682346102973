import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { startWith, delay } from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router"
import { Meta, Title } from '@angular/platform-browser';
import { FacebookLoginProvider } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute, 
  ) {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])}

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.authService.signOut().pipe(
      startWith(null),
      delay(0)
    ).subscribe(() => {
      this.router.navigate([''])
    });

    
  }
}
