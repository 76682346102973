import { Component, OnInit, Input, ElementRef, ViewChild, Type } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StoreService } from 'src/app/store.service';
import { MokaAuthentication } from 'src/environments/environment';
import { BuyerInformation, DealerPaymentServiceDirectPaymentResultData, DealerPaymentServicePaymentRequest, PaymentDealerAuthentication, PaymentDealerRequest, PaymentDetailData, productList, WebPostverifyRequest } from 'src/models/paymentModels';
import { sha256 } from 'js-sha256';


@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
 export class StoreComponent implements OnInit {

  isTypeSelected = false
  cardMonth = "";
  cardYear = "";
  Ipadress = '';
  url :any
  urlCheckin :boolean =true
  response:string = ""
  responseError:string =null
  productList:any
  productValue :number
  priceValue:number =0
  getPaymentDetails = new FormGroup(
    {
      amount: new FormControl('', [
       Validators.required,
       Validators.minLength(3),
       Validators.maxLength(30),
     ]),
     card_name: new FormControl('', [
       Validators.required,
       Validators.minLength(3),
       Validators.maxLength(20),
     ]),
     card_number: new FormControl('', [
       Validators.required,
       Validators.minLength(16),
       Validators.maxLength(20),
     ]),
     card_security_code: new FormControl('', [
       Validators.required,
       Validators.minLength(3),
       Validators.maxLength(20),
     ]),
     card_last_month: new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
    ]),
    card_last_year: new FormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
    ])
    }
  );
 
  constructor(
    private titleService:Title,
    private MetaService:Meta,
    private route: ActivatedRoute,
    private store :StoreService,
    private dom:DomSanitizer
  ) {
    let productRequest=new productList()
    productRequest.token = JSON.parse(localStorage.getItem("currentUser")).token.toString()
    productRequest.user_id = JSON.parse(localStorage.getItem("currentUser")).id.toString()
    this.store.getProductDetail(productRequest).then((res:any)=>{
      this.productList = res.result
    });
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.MetaService.updateTag(this.route.snapshot.data['metatags'])}

  ngOnInit() {
    this.getPaymentDetails.get('amount').value;
  }

  PriceAndProductValue(product,price){
    this.productValue = product;
    this.priceValue = price
  }

  onSubmitCardMonth(value) {
    this.cardMonth = value
  }

  onSubmitCardYear(value) {
    this.cardYear = value
  } 

  buttonOnClick(priceValue, product) { 
    this.priceValue = priceValue
      this.productValue = product; 
    if (!this.isTypeSelected) {
      this.isTypeSelected = true
    }
  }

  onApproveClicked() {
    this.threeSecurePayment()
  }

  private NotThreepayment(){
    let jsonParser = JSON.parse(localStorage.getItem("currentUser"));  
    console.log("current user = ", jsonParser)
    let TrxCode = jsonParser.id+"+"+new Date().getTime() / 1000
    let request = new DealerPaymentServicePaymentRequest();
    request.PaymentDealerAuthentication = new PaymentDealerAuthentication();
    request.PaymentDealerAuthentication.DealerCode = MokaAuthentication.DealerCode;
    request.PaymentDealerAuthentication.Username = MokaAuthentication.Username;
    request.PaymentDealerAuthentication.Password = MokaAuthentication.Password;
    request.PaymentDealerAuthentication.CheckKey = sha256(request.PaymentDealerAuthentication.DealerCode+"MK"+request.PaymentDealerAuthentication.Username+"PD"+request.PaymentDealerAuthentication.Password);
    request.PaymentDealerRequest = new PaymentDealerRequest();
    request.PaymentDealerRequest.CardHolderFullName = this.getPaymentDetails.value['card_name'];
    request.PaymentDealerRequest.CardNumber =this.getPaymentDetails.value['card_number'];
    request.PaymentDealerRequest.ExpYear = this.getPaymentDetails.value['card_last_year'];
    request.PaymentDealerRequest.ExpMonth =this.getPaymentDetails.value['card_last_month'];
    request.PaymentDealerRequest.CvcNumber =this.getPaymentDetails.value['card_security_code'];
    request.PaymentDealerRequest.Amount =this.priceValue
    request.PaymentDealerRequest.Currency = "TL";
    request.PaymentDealerRequest.OtherTrxCode=TrxCode
    request.PaymentDealerRequest.InstallmentNumber = 1;
    this.store.getStoreNot3DSecure(request).then((a)=>{
      this.webPosVerifyNot3D(a,jsonParser.token,jsonParser.id,request.PaymentDealerRequest.Amount,new Date().toISOString())
    })
  }


  private threeSecurePayment(){
    let jsonParser = JSON.parse(localStorage.getItem("currentUser"));  
    let TrxCode = jsonParser.id+"+"+new Date().getTime() / 1000
    let request = new DealerPaymentServicePaymentRequest();
    request.PaymentDealerAuthentication = new PaymentDealerAuthentication();
    request.PaymentDealerAuthentication.DealerCode = MokaAuthentication.DealerCode;
    request.PaymentDealerAuthentication.Username = MokaAuthentication.Username;
    request.PaymentDealerAuthentication.Password = MokaAuthentication.Password;
    request.PaymentDealerAuthentication.CheckKey = sha256(request.PaymentDealerAuthentication.DealerCode+"MK"+request.PaymentDealerAuthentication.Username+"PD"+request.PaymentDealerAuthentication.Password);
    request.PaymentDealerRequest = new PaymentDealerRequest();
    request.PaymentDealerRequest.CardHolderFullName = this.getPaymentDetails.value['card_name'];
    request.PaymentDealerRequest.CardNumber =this.getPaymentDetails.value['card_number'];
    request.PaymentDealerRequest.ExpYear = this.getPaymentDetails.value['card_last_year'];
    request.PaymentDealerRequest.ExpMonth = this.getPaymentDetails.value['card_last_month'];
    request.PaymentDealerRequest.CvcNumber = this.getPaymentDetails.value['card_security_code'];
    request.PaymentDealerRequest.Amount =this.priceValue
    request.PaymentDealerRequest.Currency = "TL";
    request.PaymentDealerRequest.InstallmentNumber = 0;
    request.PaymentDealerRequest.RedirectUrl="https://api2.falevi.com.tr/webpos/register"
    request.PaymentDealerRequest.OtherTrxCode = TrxCode
    request.PaymentDealerRequest.IsPreAuth =0
    request.PaymentDealerRequest.RedirectType =1
    let users = new BuyerInformation()
    users.BuyerFullName= JSON.parse(localStorage.getItem("currentUser"))['name'];
    users.BuyerEmail =JSON.parse(localStorage.getItem("currentUser"))['email'];
    request.PaymentDealerRequest.BuyerInformation = users
    let store3dSecure = this.store.getStore3DSecure(request)
   
    store3dSecure.then((sd:any)=>{  
      console.log('secure detail = ', sd)
      if(sd.Data != null && sd.ResultCode == "Success"){
        this.url = this.dom.bypassSecurityTrustResourceUrl(sd.Data)
        //let windowPayment = window.open(sd.Data,"popup","top=600,left=600,width=600,height=600")//
        this.ThreeDPaymentUrlPost(request.PaymentDealerRequest.OtherTrxCode,jsonParser)
        if (this.response == "ok"){
          window.location.href ="/profile"
        }
      }else{
        //window.location.href ="/store"
        console.log('an error occured = ', sd.Exception)
        if (sd.Exception.includes('Beklenmeyen bir hata oluştu')) {
          this.responseError = "Lütfen önce kredi kartı bilgilerinizi giriniz, daha sonra kredi seçimi yapabilirsini."
        }
      }
    })
  }
  

  urlChecking(){
    if(this.urlCheckin){
      return this.dom.bypassSecurityTrustResourceUrl(this.url)
    }
    this.urlCheckin = false
  }

  private ThreeDPaymentUrlPost(otherTrxCode,jsonParser) {
    setTimeout(()=>{
      let paymentDetail = this.PaymentDetailControl(otherTrxCode)
    paymentDetail.then((res)=>{      
      if(res !=null && res.PaymentTrxDetailList !=null
        ){
          if(res.PaymentTrxDetailList[0].VirtualPosOrderId !=undefined  && res.PaymentTrxDetailList[0].VirtualPosOrderId != ""){

            
            this.response = this.webPosVerify3D(res,jsonParser)
          }else{
            this.ThreeDPaymentUrlPost(otherTrxCode,jsonParser)
          }
      }
      else{
        this.ThreeDPaymentUrlPost(otherTrxCode,jsonParser)
      }})
    },10000)
  }

  private PaymentDetailControl(otherTrxCode:string){
    let request = new DealerPaymentServicePaymentRequest();
    request.PaymentDealerAuthentication = new PaymentDealerAuthentication();
    request.PaymentDealerAuthentication.DealerCode = MokaAuthentication.DealerCode;
    request.PaymentDealerAuthentication.Username = MokaAuthentication.Username;
    request.PaymentDealerAuthentication.Password = MokaAuthentication.Password;
    request.PaymentDealerAuthentication.CheckKey = sha256(request.PaymentDealerAuthentication.DealerCode+"MK"+request.PaymentDealerAuthentication.Username+"PD"+request.PaymentDealerAuthentication.Password);
    request.PaymentDealerRequest = new PaymentDealerRequest();
    request.PaymentDealerRequest.OtherTrxCode = otherTrxCode
    return this.store.getStore3dControl(request)
  }

  private webPosVerify3D(data:PaymentDetailData,jsonParser){
    let dataTime = data.PaymentDetail.PaymentDate
    let hour = new Date(new Date(dataTime).setHours(new Date(dataTime).getHours()+3)).toISOString()
    let date = (new Date(hour)).toISOString().replace('T',' ').split('.')[0]
    
    let request = new WebPostverifyRequest();
    request.token=jsonParser.token
    request.userId = jsonParser.id//Number(data.PaymentDetail.OtherTrxCode.split("+")[1])
    request.orderId = data.PaymentTrxDetailList[0].VirtualPosOrderId;
    request.price = data.PaymentDetail.Amount;
    request.status = data.IsSuccessful == true&& data.PaymentDetail.PaymentStatus == 2 && data.PaymentDetail.TrxStatus ==1 ?"ok":"false"
    request.product=this.productValue;
    request.created_at= date
    console.log('request detail 1 ', request)
    if(request.status == "ok") {
      this.store.PostWebPosVerify(request).subscribe({
        next: response => {
          console.log('request detail 4 ', response);
          window.location.href = "/profile";
        },
        error: err => {
          if (!err.status) {
            console.log("an error occured = ", err);
          }
        }
      });
    }
    return request.status
  }

  private webPosVerifyNot3D(data:DealerPaymentServiceDirectPaymentResultData,token,userId,Amount,dates){
    let date = dates.replace('T',' ').split('.')[0]    
    let request = new WebPostverifyRequest();
    request.token=token
    request.userId = Number(userId)
    request.orderId = data.VirtualPosOrderId;
    request.price = Amount;
    request.status = data.ResultCode=="Success" ?"ok":"false"
    request.product = this.productValue;
    request.created_at= date
    console.log('request detail 3 ', request)
    if(request.status == "ok") {
      this.store.PostWebPosVerify(request).subscribe({
        next: response => {
          console.log('request detail 4 ', response);
          window.location.href = "/profile";
        },
        error: err => {
          if (!err.status) {
            console.log("an error occured = ", err);
          }
        }
      }); 
    }
    return request.status
  }
 }
