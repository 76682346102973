import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-second-banner',
  templateUrl: './second-banner.component.html',
  styleUrls: ['./second-banner.component.scss']
})
export class SecondBannerComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    
  }

  onClickSeeTellers() {
    var values = JSON.parse(localStorage.getItem("currentUser"));
    if (values != null) {
      this.router.navigate(['/store'])
    } else {
      this.router.navigate(['/signin'])
    }
  }
}
